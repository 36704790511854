import React, { Component } from 'react';

import './Preview.css';

import Code from '../Code/Code';
import Snippet from '../Code/Snippet';
import generateCode from './previewData';

export default class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfJobs: 3,
            numberOfGroups: 2
        }
        this.handleDisplay = this.handleDisplay.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleGenerateCode = this.handleGenerateCode.bind(this);
    }

    handleDisplay(bool) {
        let style = bool ? 'block' : 'none';
        return style;
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePreviewOption(label, value, name, min, max) {
        const previewOption = [];
        previewOption.push(
            <div className="input-field col s12" key="0">
                <p>{label} {value}</p>
                <p className="range-field">
                    <input type="range" name={name} min={min} max={max} value={value} onChange={this.handleInputChange} />
                </p>
            </div>);
        return previewOption;
    }

    handleInsertGoogleFont(googleFont) {
        const font = googleFont.replace(/ /g, "+");
        const fontLink = 'https://fonts.googleapis.com/css?family=' + font;
        return '<link href="' + fontLink + ':300,400,700" rel="stylesheet" />';
    }

    handleBorderDisplay(visibility, element, borderWidth, borderStyle, borderColor) {
        let border;
        if (visibility) {
            border = borderWidth + 'px ' + borderStyle + ' ' + borderColor;
        } else {
            border = this.props.styles['border' + element + 'Width'] + 'px ' + this.props.styles['border' + element + 'Style'] + ' ' + this.props.styles['border' + element + 'Color'];
        }
        return border;
    }

    handlePaddingDisplay(visibility, value, element) {
        let border;
        if (visibility) {
            border = value;
        } else {
            border = this.props.styles[element];
        }
        return border;
    }

    handleGenerateCode(styles, isPreview) {
        const title = styles.globalIframeTitle === '' ? 'Career Vacancies' : styles.globalIframeTitle;
        const declareGoogleFont = styles.globalFontSource === 'googleFont' ? this.handleInsertGoogleFont(styles.globalFontType) : '';

        const borderBottomJob = this.handleBorderDisplay(styles.displayBorderJob, 'Job', styles.borderBottomJobWidth, styles.borderBottomJobStyle, styles.borderBottomJobColor);
        const borderLeftJob = this.handleBorderDisplay(styles.displayBorderJob, 'Job', styles.borderLeftJobWidth, styles.borderLeftJobStyle, styles.borderLeftJobColor);
        const borderRightJob = this.handleBorderDisplay(styles.displayBorderJob, 'Job', styles.borderRightJobWidth, styles.borderRightJobStyle, styles.borderRightJobColor);
        const borderTopJob = this.handleBorderDisplay(styles.displayBorderJob, 'Job', styles.borderTopJobWidth, styles.borderTopJobStyle, styles.borderTopJobColor);

        const paddingTopJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.paddingTopJob, 'paddingJob');
        const paddingBottomJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.paddingBottomJob, 'paddingJob');
        const paddingLeftJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.paddingLeftJob, 'paddingJob');
        const paddingRightJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.paddingRightJob, 'paddingJob');

        const marginTopJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.marginTopJob, 'marginJob');
        const marginBottomJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.marginBottomJob, 'marginJob');
        const marginLeftJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.marginLeftJob, 'marginJob');
        const marginRightJob = this.handlePaddingDisplay(styles.displayPaddingJob, styles.marginRightJob, 'marginJob');

        const paddingTopJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.paddingTopJobTitle, 'paddingJobTitle');
        const paddingBottomJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.paddingBottomJobTitle, 'paddingJobTitle');
        const paddingLeftJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.paddingLeftJobTitle, 'paddingJobTitle');
        const paddingRightJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.paddingRightJobTitle, 'paddingJobTitle');

        const marginTopJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.marginTopJobTitle, 'marginJobTitle');
        const marginBottomJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.marginBottomJobTitle, 'marginJobTitle');
        const marginLeftJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.marginLeftJobTitle, 'marginJobTitle');
        const marginRightJobTitle = this.handlePaddingDisplay(styles.displayPaddingJobTitle, styles.marginRightJobTitle, 'marginJobTitle');

        const css = `
            ${declareGoogleFont}
            <style type="text/css">
                * {
                margin: 0;
                padding: 0;
                }
            
                body {
                font-family: ${styles.globalFontType};
                text-rendering: optimizeLegibility;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                }
            
                body,
                input,
                select,
                .table,
                .dropdown>button,
                .dropdown>.dropdown-menu {
                font-size: ${styles.globalFontSize}px;
                color: ${styles.colorTextDetails};
                }
            
                .mobile-only {
                display: none;
                }
            
                input:hover[disabled],
                select:hover[disabled] {
                cursor: not-allowed;
                }
            </style>
            
            <style type="text/css">
                /* BUTTONS */
                .btn-apply {
                background: ${styles.colorButton};
                border: 0;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                -ms-border-radius: 3px;
                -o-border-radius: 3px;
                color: #ffffff;
                font-size: ${styles.globalFontSize}px;
                font-weight: ${styles.globalFontWeight};
                line-height: auto;
                padding: 8px 16px !important;
                text-decoration: none !important;
                }
            
                .btn-apply:hover {
                background: ${styles.colorButtonHover};
                color: #ffffff;
                }
            
                .btn-reset {
                background: none;
                color: #ffffff;
                }
            
                .btn-reset:hover {
                background: none;
                color: #ffffff;
                }
            
                .btn-view-type {
                background: #ffffff;
                border-color: #ffffff;
                color: #000000 !important;
                }
            </style>
            
            <style type="text/css">
                /* PAGINATION */
                #pagination .page-item a {
                color: ${styles.colorPagination};
                }
            
                #pagination .page-item a:hover {
                color: ${styles.colorPagination};
                }
            
                #pagination .page-item.active.disabled a {
                background: ${styles.colorPagination};
                color: #ffffff;
                }
            
                #pagination .page-item.disabled a {
                color: ${styles.colorPagination};
                }
            </style>
            
            <style type="text/css">
                /* FALLBACK */
                #fallback #jobs {
                background: #ffffff;
                border: 0 Solid #666666;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                color: #000000;
                padding: 0;
                }
            
                #fallback .jobgroup {
                background: transparent;
                }
            
                #fallback .jobgroup>h2 {
                background: transparent;
                font-size: ${styles.globalFontSize}px;
                }
            
                #fallback div.jobblock {
                background: #ffffff;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                margin: 0;
                padding: 15px;
                position: relative;
                border-bottom: 1px solid #dee2e6;
                }
            
                #fallback div.jobblock:hover {
                background: #fafafa;
                }
            
                #fallback div.jobblock:first-child {
                border-top: 1px solid #dee2e6;
                }
            
                #fallback div.jobblock>a:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                }
            
                #fallback span.employment_status::before {
                content: 'Job Type: ';
                }
            
                #fallback span.expires::before {
                content: 'Closing Date: ';
                }
            
                #fallback span.location::before {
                content: 'Location: ';
                }
            
                #fallback span.jobid::before {
                content: 'Job ID: ';
                }
            
                #fallback br {
                display: none;
                }

                .sortable-heading a {
                    color: ${styles.colorHeadings}
                }
            
                a.job_title {
                background: transparent;
                color: ${styles.colorTextJobTitle};
                font-size: ${parseInt(styles.globalFontSize) + 2}px;
                font-weight: ${styles.titleFontWeight};
                clear: both;
                display: inline-block;
                text-decoration: none;
                ${styles.displayPaddingJobTitle
                    ? `
                        margin-left: ${marginLeftJobTitle}px;
                        margin-right: ${marginRightJobTitle}px;
                        margin-top: ${marginTopJobTitle}px;
                        margin-bottom: ${marginBottomJobTitle}px;
                    `
                    : `margin: ${styles.marginJobTitle}px;`
                }
                ${styles.displayPaddingJobTitle
                    ? `
                        padding-left: ${paddingLeftJobTitle}px;
                        padding-right: ${paddingRightJobTitle}px;
                        padding-top: ${paddingTopJobTitle}px;
                        padding-bottom: ${paddingBottomJobTitle}px;
                    `
                    : `padding: ${styles.paddingJobTitle}px;`
                }
                }
            
                a.job_title:hover {
                color: ${styles.colorTextJobTitleHover};
                }
            
                span.employment_status {
                display: block !important;
                }
            
                span.expires,
                span.expires_at {
                display: block !important;
                }
            
                span.location {
                display: block;
                }
            
                span.jobid,
                span.reference {
                display: block;
                }
            
                span.salary_info {
                display: block;
                }
            
                span.postcode {
                display: block;
                }
            
                span.vacancies {
                display: block;
                }
            
                span.summary {
                display: block;
                }
            </style>
            
            <style type="text/css">
            
                /* FILTERS */
                #filterbar {
                color: #000000;
                }
            
                #filterbar input,
                #filterbar select {
                max-width: 23%;
                width: 100%;
                }
            
                @media screen and (max-width: 575px) {
            
                #filterbar input,
                #filterbar select {
                    max-width: 100%;
                }
            
                }
            
            </style>
            
            <style type="text/css">

                div#root {
                    background: ${styles.colorBackground};
                }
            
                span.field-label {
                    display: ${styles.displayDetailTitles ? 'inherit' : 'none'} !important;
                }

                .card-body {
                    padding: 0;
                }

                #card > div {
                    ${styles.displayPaddingJob
                        ? `
                            margin-top: ${marginTopJob}px;
                            margin-bottom: ${marginBottomJob}px;
                        `
                        : `
                            margin-top: ${styles.marginJob}px;
                            margin-bottom: ${styles.marginJob}px;
                        `
                    }
                }
            
                #card .card {
                    height: 100%;
                    border-bottom: ${borderBottomJob};
                    border-right: ${borderRightJob};
                    border-top: ${borderTopJob};
                    border-left: ${borderLeftJob};
                    border-radius: ${styles.borderJobRadius}px;
                    background: ${styles.colorJob};
                    ${styles.displayPaddingJob
                        ? `
                            margin-left: ${marginLeftJob}px;
                            margin-right: ${marginRightJob}px;
                            margin-top: ${marginTopJob}px;
                            margin-bottom: ${marginBottomJob}px;
                        `
                        : `margin: ${styles.marginJob}px;`
                    }
                    ${styles.displayPaddingJob
                        ? `
                            padding-left: ${paddingLeftJob}px;
                            padding-right: ${paddingRightJob}px;
                            padding-top: ${paddingTopJob}px;
                            padding-bottom: ${paddingBottomJob}px;
                        `
                        : `padding: ${styles.paddingJob}px;`
                    }
                }
                
                
            
                #table table.table.table-list>tbody>tr>td, #table table.table.table-list thead th {
                    border: 0;
                    border-left: ${borderLeftJob};
                    border-right: ${borderRightJob};
                    ${styles.displayPaddingJob
                        ? `
                            padding-left: ${paddingLeftJob}px;
                            padding-right: ${paddingRightJob}px;
                        `
                        : `
                            padding-left: ${styles.paddingJob}px;
                            padding-right: ${styles.paddingJob}px;
                        `
                    }
                    ${styles.displayPaddingJob
                        ? `
                            margin-left: ${marginLeftJob}px;
                            margin-right: ${marginRightJob}px;
                        `
                        : `
                            margin-left: ${styles.marginJob}px;
                            margin-right: ${styles.marginJob}px;
                        `
                    }
                }

                #table table.table.table-list>tbody>tr>td:last-child, #table table.table.table-list thead th:last-child {
                    border-right: ${borderRightJob};
                    border-bottom: ${borderBottomJob};
                    border-bottom-right-radius: ${styles.borderJobRadius}px;
                    border-bottom-left-radius: ${styles.borderJobRadius}px;
                    ${styles.displayPaddingJob
                        ? `
                            padding-right: ${paddingRightJob}px;
                            padding-bottom: ${paddingBottomJob}px;
                        `
                        : `
                            padding-right: ${styles.paddingJob}px;
                            padding-bottom: ${styles.paddingJob}px;
                        `
                    }
                    ${styles.displayPaddingJob
                        ? `
                            margin-right: ${marginRightJob}px;
                            margin-bottom: ${marginBottomJob}px;
                        `
                        : `
                            margin-right: ${styles.marginJob}px;
                            margin-bottom: ${styles.marginJob}px;
                        `
                    }
                }

                #table table.table.table-list>tbody>tr>td:first-child, #table table.table.table-list thead th:first-child {
                    border-left: ${borderLeftJob};
                    border-right: ${borderRightJob};
                    border-top: ${borderTopJob};
                    border-top-right-radius: ${styles.borderJobRadius}px;
                    border-top-left-radius: ${styles.borderJobRadius}px;
                    ${styles.displayPaddingJob
                        ? `
                            padding-top: ${paddingTopJob}px;
                            padding-left: ${paddingLeftJob}px;
                        `
                        : `
                            padding-top: ${styles.paddingJob}px;
                            padding-left: ${styles.paddingJob}px;
                        `
                    }
                    ${styles.displayPaddingJob
                        ? `
                            margin-top: ${marginTopJob}px;
                            margin-left: ${marginLeftJob}px;
                        `
                        : `
                            margin-top: ${styles.marginJob}px;
                            margin-left: ${styles.marginJob}px;
                        `
                    }
                }
                


                #table table.table-full>tbody>tr>td:last-child, #table table.table-full thead th:last-child {
                    border-right: ${borderRightJob};
                    ${styles.displayPaddingJob
                        ? `
                            padding-right: ${paddingRightJob}px;
                        `
                        : `
                            padding-right: ${styles.paddingJob}px;
                        `
                    }
                }

                #table table.table-full>tbody>tr>td:first-child, #table table.table-full thead th:first-child {
                    border-left: ${borderLeftJob};
                    ${styles.displayPaddingJob
                        ? `
                            padding-left: ${paddingLeftJob}px;
                        `
                        : `
                            padding-left: ${styles.paddingJob}px;
                        `
                    }
                }
            
                #table table.table-full>tbody>tr>td, #table table.table-full thead th {
                    border-top: ${borderTopJob};
                    border-bottom: ${borderBottomJob};
                    border-radius: ${styles.borderJobRadius}px;
                    ${styles.displayPaddingJob
                        ? `
                            margin-top: ${marginTopJob}px;
                            margin-bottom: ${marginBottomJob}px;
                        `
                        : `
                            margin-top: ${styles.marginJob}px;
                            margin-bottom: ${styles.marginJob}px;
                        `
                    }
                    ${styles.displayPaddingJob
                        ? `
                            padding-top: ${paddingTopJob}px;
                            padding-bottom: ${paddingBottomJob}px;
                        `
                        : `
                            padding-top: ${styles.paddingJob}px;
                            padding-bottom: ${styles.paddingJob}px;
                        `
                    }
                }
            
                @media screen and (max-width: 575px) {
                    #card>div {
                        margin-bottom: 1em;
                    }
                }
            </style>
            
            <style type="text/css">
                /* TABLE */
                #table table.table {
                margin-bottom: 0;
                }
            
                #table table.table thead {
                background: none;
                font-size: ${parseInt(styles.globalFontSize) - 2}px;
                }

                #table table.table>tbody>tr {
                    background: ${styles.colorJob};
                }
                
                #table table.table>tbody>tr:hover {
                    background: ${styles.colorJobHover} !important;
                }
            
                #table table.table thead a {
                white-space: nowrap;
                }
            
                #table table.table>tbody>tr>td>a.job_title {
                white-space: nowrap;
                }
            
                #table table.table span {
                color: inherit;
                }
            
                #table table.table>tbody>tr:last-child {
                border-bottom: 1px solid #dee2e6;
                }
            
                /* LIST */
                #table table.table.table-list>thead {
                display: none;
                }
            
                #table table.table.table-list>tbody>tr:first-child {
                    border-top: 1px solid #dee2e6;
                }
            
                #table table.table.table-list>tbody>tr>td {
                box-sizing: border-box;
                clear: both;
                display: block;
                }
            
                #table table.table.table-list>tbody>tr>td>a.job_title {
                white-space: normal;
                }
            
                #table table.table.table-list>tbody>tr>td>span>span {
                display: inline-block;
                
            
                #table table.table.table-list.table-bordered>tbody>tr>td {
                border: none;
                }
            
                @media screen and (max-width: 575px) {
                #table table.table>thead {
                    display: none;
                }
            
                #table table.table>tbody>tr:first-child {
                    border-top: 1px solid #dee2e6;
                }
            
                #table table.table>tbody>tr>td {
                    border-top: none !important;
                    box-sizing: border-box;
                    clear: both;
                    display: block;
                    padding: 0 1em !important;
                }
            
                #table table.table>tbody>tr>td:first-child {
                    margin-top: 15px !important;
                }
            
                #table table.table>tbody>tr>td:last-child {
                    margin-bottom: 15px !important;
                }
            
                #table table.table>tbody>tr>td>a.job_title {
                    white-space: normal;
                }
            
                #table table.table>tbody>tr>td>span>span {
                    display: inline-block;
                }
            
                #table table.table.table-bordered>tbody>tr>td {
                    border: none;
                }
            
                #table table.table-full span.field-label {
                    display: none;
                }
                }
            </style>
        `

        const code = generateCode(css, isPreview, this.props.styles);
        return code;
    }

    render() {
        const styles = this.props.styles;

        const numberOfJobsSlider = this.handlePreviewOption('Number of jobs:', this.state.numberOfJobs, 'numberOfJobs', 1, 10);
        const numberOfGroupsSlider = this.handlePreviewOption('Number of groups:', this.state.numberOfGroups, 'numberOfGroups', 1, 5);


        return (
            <div className="row">
                <ul className="tabs">
                    <li className="tab col s4"><a className="active" href="#tab-preview">Preview</a></li>
                    <li className="tab col s4"><a href="#tab-code">Code</a></li>
                    <li className="tab col s4"><a href="#tab-snippet">Snippet</a></li>
                </ul>
                {/* <div id="tab-preview" className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Preview</span>
                            <div className="card white" id="preview-window">
                                <div className="preview-header valign-wrapper">

                                    <i className="material-icons">chevron_left</i>
                                    <i className="material-icons">chevron_right</i>

                                    <div className="left white valign-wrapper">
                                        <i className="material-icons">lock</i>
                                        preview
                                    </div>
                                    <div className="right valign-wrapper">
                                        <i className="dropdown-trigger material-icons" data-target="preview_options">settings</i>
                                        <div id="preview_options" className="dropdown-content">
                                            {numberOfJobsSlider}
                                            {styles.displayJobGroup ? numberOfGroupsSlider : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="divider"></div>

                                <PreviewWindow styles={styles} checkDisplay={this.handleDisplay} numberOfJobs={this.state.numberOfJobs} numberOfGroups={this.state.numberOfGroups} />
                                <div className="preview-footer"></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id="tab-preview" className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Preview</span>
                            <div className="card white" id="preview-window">
                                <iframe
                                    title="preview"
                                    id="tab-preview"
                                    key={this.handleGenerateCode(styles, true)}
                                    src={"data:text/html;charset=utf-8," + escape(this.handleGenerateCode(styles, true))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tab-code" className="col s12">
                    <Code handleGenerateCode={this.handleGenerateCode} styles={styles} checkDisplay={this.handleDisplay} />
                </div>
                <div id="tab-snippet" className="col s12">
                    <Snippet />
                </div>
            </div>
        );
    }
}
