import React, { Component } from 'react';

import ColorPicker from './ColorPicker';
import Colours from './Colours';


export default class BasicIframe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFontSource: 'webSafeFont',
            globalFontType: 'Arial',
            globalWebSafeFontType: 'Arial',
            globalGoogleFontType: 'Exo'
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }

    handleInputChange(e) {
        this.props.onInputChange(e);

        if (e.target.id === 'globalwebsafefonttype') {
            this.setState({
                globalWebSafeFontType: e.target.value
            });
        }

        if (e.target.id === 'globalgooglefonttype') {
            this.setState({
                globalGoogleFontType: e.target.value
            });
        }
    }

    handleColorChange(color, name) {
        this.props.onColorChange(color, name);
    }

    handleRadioChange(e) {
        this.handleInputChange(e);

        if (e.target.id === 'globalwebsafefontradio') {
            const event = {
                target: {
                    name: 'globalFontType',
                    value: this.state.globalWebSafeFontType
                }
            }
            this.handleInputChange(event);
        } else {
            const event = {
                target: {
                    name: 'globalFontType',
                    value: this.state.globalGoogleFontType
                }
            }
            this.handleInputChange(event);
        }
    }

    inputText(grid, icon, id, name, label, placeholder) {
        const input = [];
        const gridStyle = 'col ' + grid;
        const iconPrefix = [];

        input.push(<div className={gridStyle} key="0">
            {icon !== '' ? iconPrefix : ''}
            <label htmlFor={id}>{label}</label>
            <input id={id} placeholder={placeholder} type="text" name={name} className="validate" onChange={this.handleInputChange} />
        </div>);
        return input;
    }

    inputSelect(grid, icon, id, name, value, label, list, fontSource) {
        let display;

        if (fontSource !== undefined) {
            let source = fontSource.slice(0, 10);
            source = source.toLowerCase();
            let source_id = id.slice(6, 16)
            display = source !== source_id ? 'hidden' : '';
        }

        let gridStyle = 'input-field col ' + grid + ' ' + display;

        const iconPrefix = [];
        iconPrefix.push(<i className="material-icons prefix" key="0">{icon}</i>);

        let options = [];
        list.forEach((option, i) => {
            options.push(<option value={option} key={i}>{option}</option>);
        });

        const select = [];
        select.push(<div className={gridStyle} key="0">
            {icon !== '' ? iconPrefix : ''}
            <select id={id} name={name} defaultValue={value} onChange={this.handleInputChange}>
                {options}
            </select>
            <label htmlFor={id}>{label}</label>
        </div>);
        return select;
    }

    inputRange(grid, icon, id, name, value, label, min, max, unit) {

        let gridStyle = 'input-field col ' + grid;
        const iconPrefix = [];
        iconPrefix.push(<i className="material-icons prefix" key="0">{icon}</i>);

        const rangeStyle = {
            fontSize: '16px',
            marginLeft: '42px',
            top: '10px'
        }

        const labelStyle = {
            fontSize: '12px',
            marginLeft: '42px',
            top: '-30px'
        }

        const range = [];
        range.push(<div className={gridStyle} key="0">
            <label htmlFor={id} style={labelStyle}>{label}</label>
            <div className="clearfix"></div>
            {icon !== '' ? iconPrefix : ''}
            <p className="range-field" style={rangeStyle}>
                {value + unit}
                <input type="range" name={name} min={min} max={max} defaultValue={value} onChange={this.handleInputChange} />
            </p>
        </div>);
        return range;
    }

    handleVisibility(label, name, check) {
        let inputField = [];
        inputField.push(<div className="input-field col s12" key="0">
            <div className="left">
                <p>{label}</p>
            </div>
            <div className="switch right">
                <label>
                    <input type="checkbox" name={name} checked={check} onChange={this.props.onInputChange} />
                    <span className="lever"></span>
                </label>
            </div>
        </div>);
        return inputField;
    }

    handlePanel(active, icon, label, labelWidth, labelStyle, labelColor, labelRadius, fieldWidth, fieldRadius, fieldStyle, colorPickerName, color, bool) {
        let panel = [];

        if (labelWidth === '0') {
            labelWidth = 'No Border';
            labelStyle = '';
            labelColor = '';
        } else {
            labelWidth = labelWidth + 'px';
        }

        const radius = ((label === 'All Sides') && (labelWidth !== 'No Border')) ? labelRadius + 'px' : '';
        const iconDisplay = ((label === 'All Sides') && (labelWidth !== 'No Border')) ? 'block' : 'none';

        const iconStyle = {
            display: iconDisplay,
            margin: '0 15px'
        };

        let panelDisplay;
        if (bool) {
            panelDisplay = label === 'All Sides' ? 'none' : 'block';
        } else {
            panelDisplay = label === 'All Sides' ? 'block' : 'none';
        }

        const panelStyle = {
            display: panelDisplay
        }

        panel.push(
            <li className={active} key="0" style={panelStyle}>
                <div className="collapsible-header valign-wrapper"><i className="material-icons">{icon}</i> {label} <span className="badge"></span>{labelWidth} {labelStyle} {labelColor} <div className="material-icons" style={iconStyle}>rounded_corner</div>{radius}</div>
                <div className="collapsible-body">
                    <span>
                        {fieldWidth}
                        {fieldRadius}
                        {fieldStyle}
                        <div className="input-field col s12">
                            <ColorPicker name={colorPickerName} width="100%" color={color} onColorChange={this.handleColorChange} />
                        </div>
                    </span>
                    <div className="clearfix"></div>
                </div>
            </li>);
        return panel;
    }

    handlePanelPadding(active, icon, label, paddingValue, marginValue, fieldPadding, fieldMargin, bool) {
        let panel = [];

        if (paddingValue === '0') {
            paddingValue = 'None';
        } else {
            paddingValue = paddingValue + 'px';
        }

        if (marginValue === '0') {
            marginValue = 'None';
        } else {
            marginValue = marginValue + 'px';
        }

        let panelDisplay;
        if (bool) {
            panelDisplay = label === 'All Sides' ? 'none' : 'block';
        } else {
            panelDisplay = label === 'All Sides' ? 'block' : 'none';
        }

        const panelStyle = {
            display: panelDisplay
        }

        panel.push(
            <li className={active} key="0" style={panelStyle}>
                <div className="collapsible-header valign-wrapper"><i className="material-icons">{icon}</i> {label} <span className="badge"></span>Padding: {paddingValue} | Margin: {marginValue}</div>
                <div className="collapsible-body">
                    <span>
                        {fieldPadding}
                        {fieldMargin}
                    </span>
                    <div className="clearfix"></div>
                </div>
            </li>);
        return panel;
    }

    render() {
        const prop = this.props.states;

        const iframeTitleField = this.inputText('s12', '', 'iframe_title', 'globalIframeTitle', 'iFrame Title', "E.g. 'Career Vacancies'");

        const webSafeFonts = ['Arial', 'Arial Black', 'Courier New', 'Impact', 'Georgia', 'Helvetica', 'Palatino', 'Times New Roman', 'Trebuchet MS', 'Verdana'];
        const googleFonts = ['Exo', 'Lato', 'Lobster', 'Merriweather Sans', 'Montserrat', 'Noto Sans', 'Nunito', 'Open Sans', 'Oswald', 'Poppins', 'PT Sans', 'Quicksand', 'Raleway', 'Roboto', 'Roboto Slab', 'Source Sans Pro', 'Ubuntu'];

        const globalWebSafeFontTypeField = this.inputSelect('s12 m12 l6', 'font_download', 'globalwebsafefonttype', 'globalFontType', prop.globalWebSafeFontType, 'Font Type', webSafeFonts, prop.globalFontSource);
        const globalGoogleFontTypeField = this.inputSelect('s12 m12 l6', 'font_download', 'globalgooglefonttype', 'globalFontType', prop.globalGoogleFontType, 'Font Type', googleFonts, prop.globalFontSource);

        const fontWeightOptions = ['Normal', 'Bold'];
        const globalFontWeightField = this.inputSelect('s12 m12 l6', 'format_bold', 'globalfontweight', 'globalFontWeight', prop.globalFontWeight, 'Font Weight', fontWeightOptions);
        const globalFontSizeField = this.inputRange('s12 m12 l12', 'format_size', 'globalfontsize', 'globalFontSize', prop.globalFontSize, 'Font Size', 8, 50, 'px');
        const titleFontWeight = this.inputSelect('s12 m12 l6', 'format_bold', 'titlefontweight', 'titleFontWeight', prop.titleFontWeight, 'Title Font Weight', fontWeightOptions);

        const jobsPerPage = this.inputRange('s12 m12 l12', 'format_size', 'jobsPerPage', 'jobsPerPage', prop.jobsPerPage, 'Jobs Per Page', 5, 30, '');

        const switchDetailTitles = this.handleVisibility('Detail Titles', 'displayDetailTitles', prop.displayDetailTitles);
        const switchTitle = this.handleVisibility('Titles', 'displayTitle', prop.displayTitle);
        const switchLocation = this.handleVisibility('Location', 'displayLocation', prop.displayLocation);
        const switchEmploymentStatus = this.handleVisibility('Employment Status', 'displayEmploymentStatus', prop.displayEmploymentStatus);
        const switchBrand = this.handleVisibility('Brand', 'displayBrand', prop.displayBrand);
        const switchVacancies = this.handleVisibility('Vacancies', 'displayVacancies', prop.displayVacancies);
        const switchCreatedAt = this.handleVisibility('Created At', 'displayCreatedAt', prop.displayCreatedAt);
        const switchExpiresAt = this.handleVisibility('Expires At', 'displayExpiresAt', prop.displayExpiresAt);
        const switchPublishedAt = this.handleVisibility('Published At', 'displayPublishedAt', prop.displayPublishedAt);
        const switchClassifications = this.handleVisibility('Classifications', 'displayClassifications', prop.displayClassifications);
        const switchButton = this.handleVisibility('Apply Button', 'displayButton', prop.displayButton);

        const switchFilterSearch = this.handleVisibility('Search', 'displayFilterSearch', prop.displayFilterSearch);
        const switchFilterLocation = this.handleVisibility('Location', 'displayFilterLocation', prop.displayFilterLocation);
        const switchFilterEmployment = this.handleVisibility('Employment', 'displayFilterEmployment', prop.displayFilterEmployment);
        const switchFilterBrands = this.handleVisibility('Brand', 'displayFilterBrands', prop.displayFilterBrands);

        // Border Fields

        const jobBorderWidthField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'borderjobwidth', 'borderJobWidth', prop.borderJobWidth, 'Border Width', 0, 50, 'px');
        const jobBorderRadiusField = this.inputRange('s12 m12 l12 xl6', 'rounded_corner', 'borderjobradius', 'borderJobRadius', prop.borderJobRadius, 'Border Radius', 0, 50, 'px');

        const jobBorderRadiusField2Visibility = prop.displayBorderJob ? { display: 'block' } : { display: 'none' };
        const jobBorderRadiusField2 = this.inputRange('s12', 'rounded_corner', 'borderjobradius', 'borderJobRadius', prop.borderJobRadius, 'Border Radius', 0, 50, 'px');

        const jobBorderTopWidthField = this.inputRange('s12 m12 l12 xl6', 'border_top', 'bordertopjobwidth', 'borderTopJobWidth', prop.borderTopJobWidth, 'Border Width', 0, 50, 'px');
        const jobBorderBottomWidthField = this.inputRange('s12 m12 l12 xl6', 'border_bottom', 'borderbottomjobwidth', 'borderBottomJobWidth', prop.borderBottomJobWidth, 'Border Width', 0, 50, 'px');
        const jobBorderLeftWidthField = this.inputRange('s12 m12 l12 xl6', 'border_left', 'borderleftjobwidth', 'borderLeftJobWidth', prop.borderLeftJobWidth, 'Border Width', 0, 50, 'px');
        const jobBorderRightWidthField = this.inputRange('s12 m12 l12 xl6', 'border_right', 'borderrightjobwidth', 'borderRightJobWidth', prop.borderRightJobWidth, 'Border Width', 0, 50, 'px');

        // Style Fields
        const borderOptions = ['Solid', 'Dotted', 'Dashed', 'Double', 'Groove', 'Inset', 'Outset', 'Ridge'];
        const jobBorderStyleField = this.inputSelect('s12', 'border_style', 'borderjobstyle', 'borderJobStyle', prop.borderJobStyle, 'Border Style', borderOptions);
        const jobBorderTopStyleField = this.inputSelect('s12 m12 l12 xl6', 'border_style', 'bordertopjobstyle', 'borderTopJobStyle', prop.borderTopJobStyle, 'Border Style', borderOptions);
        const jobBorderBottomStyleField = this.inputSelect('s12 m12 l12 xl6', 'border_style', 'borderbottomjobstyle', 'borderBottomJobStyle', prop.borderBottomJobStyle, 'Border Style', borderOptions);
        const jobBorderLeftStyleField = this.inputSelect('s12 m12 l12 xl6', 'border_style', 'borderleftjobstyle', 'borderLeftJobStyle', prop.borderLeftJobStyle, 'Border Style', borderOptions);
        const jobBorderRightStyleField = this.inputSelect('s12 m12 l12 xl6', 'border_style', 'borderrightjobstyle', 'borderRightJobStyle', prop.borderRightJobStyle, 'Border Style', borderOptions);


        // Padding Fields
        const jobPaddingField = this.inputRange('s12 m12 l12 xl6', 'format_indent_increase', 'paddingjob', 'paddingJob', prop.paddingJob, 'Padding', 0, 50, 'px');
        const jobMarginField = this.inputRange('s12 m12 l12 xl6', 'format_indent_decrease', 'marginjob', 'marginJob', prop.marginJob, 'Margin', 0, 50, 'px');

        const jobPaddingTopField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingtopjob', 'paddingTopJob', prop.paddingTopJob, 'Padding', 0, 50, 'px');
        const jobPaddingBottomField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingbottomjob', 'paddingBottomJob', prop.paddingBottomJob, 'Padding', 0, 50, 'px');
        const jobPaddingLeftField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingleftjob', 'paddingLeftJob', prop.paddingLeftJob, 'Padding', 0, 50, 'px');
        const jobPaddingRightField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingrightjob', 'paddingRightJob', prop.paddingRightJob, 'Padding', 0, 50, 'px');

        const jobMarginTopField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'margintopjob', 'marginTopJob', prop.marginTopJob, 'Margin', 0, 50, 'px');
        const jobMarginBottomField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginbottomjob', 'marginBottomJob', prop.marginBottomJob, 'Margin', 0, 50, 'px');
        const jobMarginLeftField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginleftjob', 'marginLeftJob', prop.marginLeftJob, 'Margin', 0, 50, 'px');
        const jobMarginRightField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginrightjob', 'marginRightJob', prop.marginRightJob, 'Margin', 0, 50, 'px');


        const jobtitlePaddingField = this.inputRange('s12 m12 l12 xl6', 'format_indent_increase', 'paddingjobtitle', 'paddingJobTitle', prop.paddingJobTitle, 'Padding', 0, 50, 'px');
        const jobtitleMarginField = this.inputRange('s12 m12 l12 xl6', 'format_indent_decrease', 'marginjobtitle', 'marginJobTitle', prop.marginJobTitle, 'Margin', 0, 50, 'px');

        const jobtitlePaddingTopField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingtopjobtitle', 'paddingTopJobTitle', prop.paddingTopJobTitle, 'Padding', 0, 50, 'px');
        const jobtitlePaddingBottomField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingbottomjobtitle', 'paddingBottomJobTitle', prop.paddingBottomJobTitle, 'Padding', 0, 50, 'px');
        const jobtitlePaddingLeftField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingleftjobtitle', 'paddingLeftJobTitle', prop.paddingLeftJobTitle, 'Padding', 0, 50, 'px');
        const jobtitlePaddingRightField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'paddingrightjobtitle', 'paddingRightJobTitle', prop.paddingRightJobTitle, 'Padding', 0, 50, 'px');

        const jobtitleMarginTopField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'margintopjobtitle', 'marginTopJobTitle', prop.marginTopJobTitle, 'Margin', 0, 50, 'px');
        const jobtitleMarginBottomField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginbottomjobtitle', 'marginBottomJobTitle', prop.marginBottomJobTitle, 'Margin', 0, 50, 'px');
        const jobtitleMarginLeftField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginleftjobtitle', 'marginLeftJobTitle', prop.marginLeftJobTitle, 'Margin', 0, 50, 'px');
        const jobtitleMarginRightField = this.inputRange('s12 m12 l12 xl6', 'border_outer', 'marginrightjobtitle', 'marginRightJobTitle', prop.marginRightJobTitle, 'Margin', 0, 50, 'px');

        // Panels
        const panelBorderJob = this.handlePanel('', 'border_outer', 'All Sides', prop.borderJobWidth, prop.borderJobStyle, prop.borderJobColor, prop.borderJobRadius, jobBorderWidthField, jobBorderRadiusField, jobBorderStyleField, 'borderJobColor', prop.borderJobColor, prop.displayBorderJob);
        const panelBorderTopJob = this.handlePanel('', 'border_top', 'Top', prop.borderTopJobWidth, prop.borderTopJobStyle, prop.borderTopJobColor, '', jobBorderTopWidthField, '', jobBorderTopStyleField, 'borderTopJobColor', prop.borderTopJobColor, prop.displayBorderJob);
        const panelBorderBottomJob = this.handlePanel('', 'border_bottom', 'Bottom', prop.borderBottomJobWidth, prop.borderBottomJobStyle, prop.borderBottomJobColor, '', jobBorderBottomWidthField, '', jobBorderBottomStyleField, 'borderBottomJobColor', prop.borderBottomJobColor, prop.displayBorderJob);
        const panelBorderLeftJob = this.handlePanel('', 'border_left', 'Left', prop.borderLeftJobWidth, prop.borderLeftJobStyle, prop.borderLeftJobColor, '', jobBorderLeftWidthField, '', jobBorderLeftStyleField, 'borderLeftJobColor', prop.borderLeftJobColor, prop.displayBorderJob);
        const panelBorderRightJob = this.handlePanel('', 'border_right', 'Right', prop.borderRightJobWidth, prop.borderRightJobStyle, prop.borderRightJobColor, '', jobBorderRightWidthField, '', jobBorderRightStyleField, 'borderRightJobColor', prop.borderRightJobColor, prop.displayBorderJob);

        const switchBorderJob = this.handleVisibility('Manage Individual Sides', 'displayBorderJob', prop.displayBorderJob);

        // Panel Padding
        const panelPaddingJob = this.handlePanelPadding('', 'border_outer', 'All Sides', prop.paddingJob, prop.marginJob, jobPaddingField, jobMarginField, prop.displayPaddingJob);
        const panelPaddingTopJob = this.handlePanelPadding('', 'border_top', 'Top', prop.paddingTopJob, prop.marginTopJob, jobPaddingTopField, jobMarginTopField, prop.displayPaddingJob);
        const panelPaddingBottomJob = this.handlePanelPadding('', 'border_bottom', 'Bottom', prop.paddingBottomJob, prop.marginBottomJob, jobPaddingBottomField, jobMarginBottomField, prop.displayPaddingJob);
        const panelPaddingLeftJob = this.handlePanelPadding('', 'border_left', 'Left', prop.paddingLeftJob, prop.marginLeftJob, jobPaddingLeftField, jobMarginLeftField, prop.displayPaddingJob);
        const panelPaddingRightJob = this.handlePanelPadding('', 'border_right', 'Right', prop.paddingRightJob, prop.marginRightJob, jobPaddingRightField, jobMarginRightField, prop.displayPaddingJob);


        // Padding Switch
        const switchPaddingJob = this.handleVisibility('Manage Individual Sides', 'displayPaddingJob', prop.displayPaddingJob);

        // Panel Job Title Padding

        const panelPaddingJobTitle = this.handlePanelPadding('', 'border_outer', 'All Sides', prop.paddingJobTitle, prop.marginJobTitle, jobtitlePaddingField, jobtitleMarginField, prop.displayPaddingJobTitle);
        const panelPaddingTopJobTitle = this.handlePanelPadding('', 'border_top', 'Top', prop.paddingTopJobTitle, prop.marginTopJobTitle, jobtitlePaddingTopField, jobtitleMarginTopField, prop.displayPaddingJobTitle);
        const panelPaddingBottomJobTitle = this.handlePanelPadding('', 'border_bottom', 'Bottom', prop.paddingBottomJobTitle, prop.marginBottomJobTitle, jobtitlePaddingBottomField, jobtitleMarginBottomField, prop.displayPaddingJobTitle);
        const panelPaddingLeftJobTitle = this.handlePanelPadding('', 'border_left', 'Left', prop.paddingLeftJobTitle, prop.marginLeftJobTitle, jobtitlePaddingLeftField, jobtitleMarginLeftField, prop.displayPaddingJobTitle);
        const panelPaddingRightJobTitle = this.handlePanelPadding('', 'border_right', 'Right', prop.paddingRightJobTitle, prop.marginRightJobTitle, jobtitlePaddingRightField, jobtitleMarginRightField, prop.displayPaddingJobTitle);

        // Padding Job Title Switch
        const switchPaddingJobTitle = this.handleVisibility('Manage Individual Sides', 'displayPaddingJobTitle', prop.displayPaddingJobTitle);

        return (
            <div className="row">
                <ul className="tabs">
                    <li className="tab col s3"><a className="active" href="#tab-settings">Settings</a></li>
                    <li className="tab col s3"><a href="#tab-border">Borders</a></li>
                    <li className="tab col s3"><a href="#tab-padding">Paddings</a></li>
                    <li className="tab col s3"><a href="#tab-colours">Colours</a></li>
                </ul>
                <div id="tab-settings" className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Settings</span>
                            <div className="card-panel no-padding">
                                <ul className="tabs tabs-secondary">
                                    <li className="tab col s6"><a href="#tab-general" className="active">General</a></li>
                                    <li className="tab col s6"><a href="#tab-advanced">Advanced</a></li>
                                </ul>
                                <div className="col s12" id="tab-general">
                                    {iframeTitleField}
                                    <div className="mb-2 row" >
                                        <div className="col s6">
                                            <label>
                                                <input id="globalwebsafefontradio" name="globalFontSource" type="radio" defaultChecked defaultValue="webSafeFont" onChange={this.handleRadioChange} />
                                                <span htmlFor="globalwebsafefontradio">Web Safe Fonts</span>
                                            </label>
                                        </div>
                                        <div className="col s6">
                                            <label>
                                                <input id="globalgooglefontradio" name="globalFontSource" type="radio" defaultValue="googleFont" onChange={this.handleRadioChange} />
                                                <span htmlFor="globalgooglefontradio">Google Fonts</span>
                                            </label>
                                        </div>
                                    </div>

                                    {globalWebSafeFontTypeField}
                                    {globalGoogleFontTypeField}
                                    {globalFontWeightField}
                                    {globalFontSizeField}
                                    {titleFontWeight}
                                    {jobsPerPage}
                                </div>
                                <div className="clearfix" />
                                <div className="col s12" id="tab-advanced">
                                    <div className="mb-2 row" style={{ marginTop: 20 }}>
                                        <div className="col s4">
                                            <label>
                                                <input name="layoutStyle" type="radio" value="table" onChange={this.props.onInputChange} defaultChecked />
                                                <span htmlFor="globalwebsafefontradio">Table</span>
                                            </label>
                                        </div>
                                        <div className="col s4">
                                            <label>
                                                <input name="layoutStyle" type="radio" value="card" onChange={this.props.onInputChange} />
                                                <span htmlFor="globalgooglefontradio">Card</span>
                                            </label>
                                        </div>
                                        <div className="col s4">
                                            <label>
                                                <input name="layoutStyle" type="radio" value="list" onChange={this.props.onInputChange} />
                                                <span htmlFor="globalgooglefontradio">List</span>
                                            </label>
                                        </div>
                                    </div>
                                    {jobsPerPage}
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Displayed Fields</span>
                            <div className="card-panel no-padding">
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="tabs tabs-secondary">
                                            <li className="tab col s6"><a href="#tab-fields" className="active">Fields</a></li>
                                            <li className="tab col s6"><a href="#tab-filters">Filters</a></li>
                                        </ul>
                                        <div className="col s12">
                                            <div id="tab-fields">
                                                {switchDetailTitles}
                                                {switchTitle}
                                                {switchLocation}
                                                {switchEmploymentStatus}
                                                {switchBrand}
                                                {switchVacancies}
                                                {switchCreatedAt}
                                                {switchExpiresAt}
                                                {switchPublishedAt}
                                                {switchClassifications}
                                                {switchButton}
                                                <div className="clearfix"></div>
                                            </div>
                                            <div id="tab-filters">
                                                {switchFilterSearch}
                                                {switchFilterLocation}
                                                {switchFilterEmployment}
                                                {switchFilterBrands}
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tab-border" className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Borders</span>
                            <div className="card-panel no-padding">
                                <div className="row no-margin-bottom">
                                    <div className="col s12">
                                        <div className="col s12">
                                            <div id="tab-innerborder">
                                                {switchBorderJob}
                                                <div className="clearfix"></div>
                                                <ul className="collapsible">
                                                    {panelBorderJob}
                                                    {panelBorderTopJob}
                                                    {panelBorderBottomJob}
                                                    {panelBorderLeftJob}
                                                    {panelBorderRightJob}
                                                </ul>
                                                <div style={jobBorderRadiusField2Visibility}>
                                                    {jobBorderRadiusField2}
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="tab-padding" className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Paddings &amp; Margins</span>
                            <div className="card-panel no-padding">
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="tabs tabs-secondary">
                                            <li className="tab col s6"><a href="#tab-jobs" className="active">Jobs</a></li>
                                            <li className="tab col s6"><a href="#tab-title">Title</a></li>
                                        </ul>
                                        <div className="col s12">
                                            <div id="tab-jobs">
                                                {switchPaddingJob}
                                                <div className="clearfix"></div>
                                                <ul className="collapsible">
                                                    {panelPaddingJob}
                                                    {panelPaddingTopJob}
                                                    {panelPaddingBottomJob}
                                                    {panelPaddingLeftJob}
                                                    {panelPaddingRightJob}
                                                </ul>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div id="tab-title">
                                                {switchPaddingJobTitle}
                                                <div className="clearfix"></div>
                                                <ul className="collapsible">
                                                    {panelPaddingJobTitle}
                                                    {panelPaddingTopJobTitle}
                                                    {panelPaddingBottomJobTitle}
                                                    {panelPaddingLeftJobTitle}
                                                    {panelPaddingRightJobTitle}
                                                </ul>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="tab-colours" className="col s12">
                    <Colours states={this.props.states} onColorChange={this.handleColorChange} onInputChange={this.handleInputChange} />
                </div>

            </div>

        );
    }
}
