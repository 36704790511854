import React, { Component } from 'react';

import BasicIframe from './BasicIframe/BasicIframe';
import Preview from './Preview/Preview';

export default class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalIframeTitle: '',
            globalFontSource: 'webSafeFont',
            globalFontType: 'Arial',
            globalFontSize: '16',
            globalFontWeight: 'Normal',
            globalLinkColor: '#0000ff',
            DetailsColor: '#000000',
            titleFontWeight: "Normal",
            layoutStyle: 'table',

            colorTextJobGroupLabel: '#000000',
            colorTextJobTitle: '#0000ff',
            colorTextJobTitleHover: '#000000',
            colorTextDetails: '#000000',
            colorTextHeaders: '#000000',
            colorButton: '#000000',
            colorButtonHover: '#000000',
            colorPagination: '#000000',
            colorHeadings: '#000000',

            colorBackground: 'transparent',
            colorJob: 'transparent',
            colorJobHover: 'transparent',

            displayDetailTitles: false,
            displayTitle: true,
            displayLocation: true,
            displayEmploymentStatus: true,
            displayBrand: true,
            displayVacancies: true,
            displayCreatedAt: true,
            displayExpiresAt: true,
            displayPublishedAt: true,
            displayClassifications: true,
            displayButton: true,
            displayPagination: true,

            displayFilterSearch: true,
            displayFilterLocation: true,
            displayFilterEmployment: true,
            displayFilterBrands: true,

            displayColorBackground: false,
            displayColorJob: false,
            displayColorJobHover: false,

            jobsPerPage: '20',

            // Paddings
            
            displayPaddingJob: false, // All Sides
            paddingJob: '15',
            marginJob: '0',

            paddingTopJob: '15',
            paddingBottomJob: '15',
            paddingLeftJob: '15',
            paddingRightJob: '15',

            marginTopJob: '0',
            marginBottomJob: '0',
            marginLeftJob: '0',
            marginRightJob: '0',

            displayPaddingJobTitle: false, // All Sides
            paddingJobTitle: '0',
            marginJobTitle: '0',

            paddingTopJobTitle: '0',
            paddingBottomJobTitle: '0',
            paddingLeftJobTitle: '0',
            paddingRightJobTitle: '0',

            marginTopJobTitle: '0',
            marginBottomJobTitle: '0',
            marginLeftJobTitle: '0',
            marginRightJobTitle: '0',

            paddingTopJobHeaders: '0',
            paddingBottomJobHeaders: '0',
            paddingLeftJobHeaders: '0',
            paddingRightJobHeaders: '0',

            marginTopJobHeaders: '0',
            marginBottomJobHeaders: '0',
            marginLeftJobHeaders: '0',
            marginRightJobHeaders: '0',

            paddingTopJobFilters: '0',
            paddingBottomJobFilters: '0',
            paddingLeftJobFilters: '0',
            paddingRightJobFilters: '0',

            marginTopJobFilters: '0',
            marginBottomJobFilters: '0',
            marginLeftJobFilters: '0',
            marginRightJobFilters: '0',


            displayPaddingJobGroupLabel: true, // All Sides
            paddingJobGroupLabel: '0',
            marginJobGroupLabel: '0',

            paddingTopJobGroupLabel: '0',
            paddingBottomJobGroupLabel: '0',
            paddingLeftJobGroupLabel: '0',
            paddingRightJobGroupLabel: '0',

            marginTopJobGroupLabel: '0',
            marginBottomJobGroupLabel: '0',
            marginLeftJobGroupLabel: '0',
            marginRightJobGroupLabel: '0',


            // Border Job
            displayBorderJob: true, // All Sides
            borderJobColor: '#333333',
            borderJobStyle: 'Solid',
            borderJobWidth: '0',
            borderJobRadius: '0',
            borderBottomJobColor: '#cccccc',
            borderBottomJobStyle: 'Solid',
            borderBottomJobWidth: '1',
            borderLeftJobColor: '#cccccc',
            borderLeftJobStyle: 'Solid',
            borderLeftJobWidth: '0',
            borderRightJobColor: '#cccccc',
            borderRightJobStyle: 'Solid',
            borderRightJobWidth: '0',
            borderTopJobColor: '#cccccc',
            borderTopJobStyle: 'Solid',
            borderTopJobWidth: '0',

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
    }

    handleInputChange(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [e.target.name]: value
        });
    }

    handleColorChange(color, name) {
        this.setState({
            [name]: color
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row blue-grey lighten-4 no-margin-bottom">
                    <div className="col s12 m6 l5">
                        <BasicIframe onInputChange={this.handleInputChange} onColorChange={this.handleColorChange} states={this.state} />
                    </div>
                    <div className="col s12 m6 l7">
                        <Preview styles={this.state} />
                    </div>
                </div>
                {/* TODO: Floating Action Button */}
                {/* <div className="fixed-action-btn">
                    <a href="#tab-preview" className="btn-floating btn-large red">
                        <i className="large material-icons tooltipped" data-position="left"
                            data-tooltip="Preview">pageview</i>
                    </a>
                    <ul>
                        <li><a href="#tab-code" className="btn-floating red"><i className="material-icons tooltipped" data-position="left"
                            data-tooltip="View Code">code</i></a></li>
                        <li><a href="#tab-snippet" className="btn-floating blue"><i className="material-icons tooltipped" data-position="left"
                            data-tooltip="View Snippet">code</i></a></li>
                    </ul>
                </div> */}
            </React.Fragment>
        );
    }
}
