const mockData = [
  {
    address_state: "QLD",
    brand: "Onboard",
    brand_url: "https://onboardhq.com/",
    classifications: "Community Services",
    created_at: "2022-12-03 04:30:00 +1000",
    employment_status: "Offered",
    expires: "08 Apr 2032 AEST",
    expires_at: "2031-11-28 06:00:00 +1000",
    external: "false",
    published_at: "11 Dec 2022",
    id: "342",
    internal: "false",
    location: "Brisbane City",
    postcode: "4000",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_002",
    salary_info: "The salary for this position is competitive and commensurate with experience. We offer excellent benefits packages and opportunities for advancement within the company.",
    summary: "We are seeking a Community Support Professional to join our team. In this role, you will be responsible for providing support to our community of users, responding to inquiries and resolving issues in a timely and professional manner.",
    tag_list: "customer service, support, community",
    title: "Community Support Professional",
    vacancies: "9"
  },
  {
    address_state: "QLD",
    brand: "Recruit",
    brand_url: "https://recruithq.com/",
    classifications: "Sales",
    created_at: "2022-07-12 11:45:00 +1000",
    employment_status: "Filled",
    expires: "18 Feb 2032 AEST",
    expires_at: "2032-02-17 16:15:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "498",
    internal: "true",
    location: "Southport",
    postcode: "4215",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_003",
    salary_info: "The salary for this position is competitive and commensurate with experience. We offer excellent benefits packages and opportunities for advancement within the company.",
    summary: "We are seeking a talented and experienced Sales Manager to join our team. In this role, you will be responsible for driving sales and managing a team of sales professionals to achieve business goals and objectives.",
    tag_list: "sales, management",
    title: "Sales Manager",
    vacancies: "1"
  },
  {
    address_state: "VIC",
    brand: "Onboard",
    brand_url: "https://onboard.io/",
    classifications: "Marketing",
    created_at: "2022-10-05 15:30:00 +1000",
    employment_status: "Open",
    expires: "11 Apr 2032 AEST",
    expires_at: "2031-12-25 12:00:00 +1000",
    external: "true",
    published_at: "31 Jan 2023",
    id: "16",
    internal: "false",
    location: "St Kilda",
    postcode: "3182",
    public_url: "https://onboard.io/",
    reference: "REF_016",
    salary_info: "This position offers a competitive salary package, including bonus potential and benefits such as health insurance and paid time off.",
    summary: "We are seeking a highly motivated individual to join our team as a Marketing Specialist. In this role, you will be responsible for developing and executing marketing campaigns to promote our products and services.",
    tag_list: "marketing, campaign, specialist",
    title: "Marketing Specialist",
    vacancies: "10"
  },
  {
    address_state: "NSW",
    brand: "Recruit",
    brand_url: "https://recruit.com.au/",
    classifications: "Software Development",
    created_at: "2022-06-30 08:45:00 +1000",
    employment_status: "Offered",
    expires: "27 Feb 2032 AEST",
    expires_at: "2031-11-14 18:00:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "17",
    internal: "true",
    location: "Sydney",
    postcode: "2000",
    public_url: "https://recruit.com.au/",
    reference: "REF_017",
    salary_info: "We offer competitive salaries and benefits, including health insurance and a retirement plan.",
    summary: "We are seeking a highly motivated individual to join our team as a Software Developer. In this role, you will be responsible for developing and maintaining software applications to meet the needs of our clients.",
    tag_list: "software, developer",
    title: "Software Developer",
    vacancies: "5"
  },
  {
    address_state: "QLD",
    brand: "Scout Talent",
    brand_url: "https://scouttalenthq.com/",
    classifications: "Project Management",
    created_at: "2022-12-01 14:15:00 +1000",
    employment_status: "Filled",
    expires: "15 Sep 2032 AEST",
    expires_at: "2032-01-01 00:00:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "18",
    internal: "true",
    location: "Brisbane",
    postcode: "4000",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_018",
    salary_info: "This position offers a competitive salary package and benefits such as health insurance and a retirement plan.",
    summary: "We are seeking an experienced Project Manager to oversee the planning, implementation, and tracking of client projects. The successful candidate will be responsible for ensuring that projects are completed on time, within budget, and meet client expectations.",
    tag_list: "project, manager",
    title: "Project Manager",
    vacancies: "1"
  },
  {
    address_state: "NSW",
    brand: "Recruit",
    brand_url: "https://recruit.com.au/",
    classifications: "Marketing",
    created_at: "2022-12-03 11:30:00 +1000",
    employment_status: "Open",
    expires: "12 Mar 2032 AEST",
    expires_at: "2031-12-15 08:00:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "11",
    internal: "false",
    location: "Newtown",
    postcode: "2042",
    public_url: "https://recruit.com.au/",
    reference: "REF_011",
    salary_info: "This position offers a competitive salary package based on experience.",
    summary: "We are seeking an experienced Marketing Coordinator to join our team. The successful candidate will be responsible for developing and implementing marketing strategies across all channels.",
    tag_list: "marketing, coordinator, strategy",
    title: "Marketing Coordinator",
    vacancies: "15"
  },
  {
    address_state: "VIC",
    brand: "Onboard",
    brand_url: "https://onboardhq.com/",
    classifications: "Software Development",
    created_at: "2022-09-22 14:15:00 +1000",
    employment_status: "Offered",
    expires: "01 Aug 2032 AEST",
    expires_at: "2032-07-12 13:45:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "12",
    internal: "true",
    location: "South Yarra",
    postcode: "3141",
    public_url: "https://onboardhq.com/",
    reference: "REF_012",
    salary_info: "We offer a competitive salary and benefits package, commensurate with experience and qualifications.",
    summary: "We are seeking a talented and motivated Full Stack Developer to join our team. You will be responsible for developing and maintaining web-based applications and supporting our clients.",
    tag_list: "full stack, developer, web-based applications",
    title: "Full Stack Developer",
    vacancies: "8"
  },
  {
    address_state: "QLD",
    brand: "Scout Talent",
    brand_url: "https://scouttalenthq.com/",
    classifications: "Marketing",
    created_at: "2022-12-19 16:45:00 +1000",
    employment_status: "Filled",
    expires: "20 May 2032 AEST",
    expires_at: "2032-05-01 09:00:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "13",
    internal: "false",
    location: "Fortitude Valley",
    postcode: "4006",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_013",
    salary_info: "The salary for this position is negotiable based on experience and qualifications.",
    summary: "We are seeking a highly motivated and experienced Digital Marketing Manager to join our team. You will be responsible for developing and implementing digital marketing strategies to promote our brand and services.",
    tag_list: "digital marketing, manager, strategy",
    title: "Digital Marketing Manager",
    vacancies: "1"
  },
  {
    address_state: "NSW",
    brand: "Recruit",
    brand_url: "https://recruit.com.au/",
    classifications: "Community Services",
    created_at: "2022-11-07 10:45:00 +1000",
    employment_status: "Open",
    expires: "12 Apr 2032 AEST",
    expires_at: "2031-11-28 13:00:00 +1000",
    external: "false",
    published_at: "31 Nov 2023",
    id: "19",
    internal: "false",
    location: "Bondi",
    postcode: "2026",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_019",
    salary_info: "This position offers a competitive salary package with great benefits including health insurance, paid time off, and retirement savings plans.",
    summary: "We are currently seeking a passionate and motivated Community Support Professional to join our team in Bondi. In this role, you will provide support to our clients and help them to achieve their goals and lead a fulfilling life.",
    tag_list: "community, support, professional",
    title: "Community Support Professional",
    vacancies: "13"
  },
  {
    address_state: "VIC",
    brand: "Scout Talent",
    brand_url: "https://scouttalenthq.com/",
    classifications: "Marketing",
    created_at: "2022-12-24 15:30:00 +1000",
    employment_status: "Offered",
    expires: "30 Apr 2032 AEST",
    expires_at: "2032-01-01 01:00:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "20",
    internal: "false",
    location: "St Kilda",
    postcode: "3182",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_020",
    salary_info: "The salary for this position is competitive and commensurate with experience.",
    summary: "We are looking for an experienced Marketing Manager to join our team in St Kilda. In this role, you will be responsible for developing and executing marketing campaigns that drive business growth and increase brand awareness.",
    tag_list: "marketing, manager",
    title: "Marketing Manager",
    vacancies: "24"
  },
  {
    address_state: "QLD",
    brand: "Onboard",
    brand_url: "https://onboard.com.au/",
    classifications: "Software Development",
    created_at: "2022-12-31 12:00:00 +1000",
    employment_status: "Open",
    expires: "30 Apr 2032 AEST",
    expires_at: "2032-03-10 17:30:00 +1000",
    external: "false",
    published_at: "31 Jan 2023",
    id: "21",
    internal: "false",
    location: "Fortitude Valley",
    postcode: "4006",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_021",
    salary_info: "We offer a competitive salary package and a range of benefits including flexible working arrangements, professional development opportunities, and a supportive team culture.",
    summary: "We are seeking a skilled and experienced Software Developer to join our team in Fortitude Valley. In this role, you will be responsible for designing, developing, and maintaining software applications that meet the needs of our clients.",
    tag_list: "software, developer",
    title: "Software Developer",
    vacancies: "8"
  },
  {
    address_state: "NSW",
    brand: "Onboard",
    brand_url: "https://onboardflow.com/",
    classifications: "Product Management",
    created_at: "2022-12-10 16:30:00 +1000",
    employment_status: "Open",
    expires: "05 Apr 2032 AEST",
    expires_at: "2031-11-01 00:00:00 +1000",
    external: "false",
    published_at: "18 Dec 2022",
    id: "11",
    internal: "true",
    location: "Manly",
    postcode: "2095",
    public_url: "https://onboardflow.com/",
    reference: "REF_011",
    salary_info: "This position offers a competitive salary package with benefits including health insurance and 401k matching.",
    summary: "We are looking for a skilled and experienced Product Manager to join our team in Manly. The ideal candidate will have a strong background in software development and will be responsible for managing the development of new products from concept to launch.",
    tag_list: "Product Management, Software Development, Agile",
    title: "Product Manager",
    vacancies: "10"
  },
  {
    address_state: "WA",
    brand: "Recruit",
    brand_url: "https://recruitnow.com.au/",
    classifications: "Marketing",
    created_at: "2022-09-01 08:45:00 +1000",
    employment_status: "Offered",
    expires: "25 Apr 2032 AEST",
    expires_at: "2031-12-12 00:00:00 +1000",
    external: "true",
    published_at: "18 Oct 2022",
    id: "12",
    internal: "false",
    location: "Perth",
    postcode: "6000",
    public_url: "https://recruitnow.com.au/",
    reference: "REF_012",
    salary_info: "We offer a competitive salary package, commensurate with experience and skills.",
    summary: "We are seeking a highly motivated and experienced Marketing Manager to join our team in Perth. The successful candidate will be responsible for developing and implementing marketing strategies that drive business growth and engage with customers.",
    tag_list: "Marketing, Strategy, Customer Engagement",
    title: "Marketing Manager",
    vacancies: "25"
  },
  {
    address_state: "VIC",
    brand: "Recruit",
    brand_url: "https://recruitnow.com.au/",
    classifications: "Human Resources",
    created_at: "2022-11-15 13:15:00 +1000",
    employment_status: "Open",
    expires: "22 Apr 2032 AEST",
    expires_at: "2031-09-05 00:00:00 +1000",
    external: "false",
    published_at: "18 Nov 2022",
    id: "13",
    internal: "true",
    location: "St Kilda",
    postcode: "3182",
    public_url: "https://recruitnow.com.au/",
    reference: "REF_013",
    salary_info: "The successful candidate will receive a competitive salary package, with opportunities for career progression.",
    summary: "We are seeking a highly motivated and experienced Human Resources Manager to join our team in St Kilda. The ideal candidate will have a strong background in HR management and will be responsible for overseeing all aspects of human resources for the company.",
    tag_list: "Human Resources, HR Management, Recruitment",
    title: "Human Resources Manager",
    vacancies: "15"
  },
  {
    address_state: "NSW",
    brand: "Scout Talent",
    brand_url: "https://scouttalenthq.com/",
    classifications: "Marketing",
    created_at: "2022-09-01 10:00:00 +1000",
    employment_status: "Offered",
    expires: "17 Apr 2032 AEST",
    expires_at: "2031-11-12 18:00:00 +1000",
    external: "false",
    published_at: "15 Oct 2022",
    id: "7",
    internal: "true",
    location: "Surry Hills",
    postcode: "2010",
    public_url: "https://scouttalenthq.com/",
    reference: "REF_007",
    salary_info: "The successful candidate will receive a competitive salary package with opportunities for growth and development.",
    summary: "We are seeking a motivated individual with a passion for marketing and communications to join our team in Surry Hills.",
    tag_list: "marketing, communications, social media",
    title: "Marketing Communications Coordinator",
    vacancies: "12"
  },
  {
    address_state: "VIC",
    brand: "Recruit",
    brand_url: "https://www.recruitnow.com.au/",
    classifications: "Product Management",
    created_at: "2022-12-05 11:30:00 +1000",
    employment_status: "Open",
    expires: "02 May 2032 AEST",
    expires_at: "2032-01-15 15:00:00 +1000",
    external: "true",
    published_at: "11 Dec 2022",
    id: "8",
    internal: "false",
    location: "Melbourne",
    postcode: "3000",
    public_url: "https://www.recruitnow.com.au/",
    reference: "REF_008",
    salary_info: "Salary is negotiable based on experience and qualifications.",
    summary: "We are looking for an experienced Project Manager to oversee multiple projects across various industries.",
    tag_list: "project management, leadership, communication",
    title: "Project Manager",
    vacancies: "10"
  },
  {
    address_state: "NSW",
    brand: "Onboard",
    brand_url: "https://www.onboardflow.com/",
    classifications: "Accounting",
    created_at: "2022-11-20 09:00:00 +1000",
    employment_status: "Offered",
    expires: "28 Apr 2032 AEST",
    expires_at: "2032-03-12 12:00:00 +1000",
    external: "false",
    published_at: "11 Dec 2022",
    id: "9",
    internal: "true",
    location: "North Sydney",
    postcode: "2060",
    public_url: "https://www.onboardflow.com/",
    reference: "REF_009",
    salary_info: "We offer a competitive salary package with opportunities for growth and development.",
    summary: "We are seeking an experienced Account Manager to join our team in North Sydney.",
    tag_list: "account management, customer service, sales",
    title: "Account Manager",
    vacancies: "5"
  }
]



function getJobs(isPreview) {
  if (isPreview) {
    return JSON.stringify(mockData)
  } else {
    return `document.querySelectorAll('#joblist .jobblock')`
  }
}

const generateCode = (styles: string, isPreview: boolean, styleObject) => {

  const jobs = getJobs(isPreview)

  return (` <!doctype html>
  <!-- Reference#: ##### -->
  <html class="no-js" lang="en">

  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta charset="utf-8" />
    <title>Career Vacancies</title>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    <!-- BOOTSTRAP CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    <!-- Fonts -->
    <link rel="stylesheet" href="https://use.typekit.net/koa7zyg.css">
    <!-- <link rel="stylesheet" href="https://use.typekit.net/zum4gyq.css" /> -->
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
      integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous" />

    <!-- On Page Style Overide Here -->
    
    ${styles}

    <style type="text/css">
      @media screen and (max-width: 575px) {
        .mobile-only {
          display: block;
        }
      }
    </style>

    <style type="text/css">
      #fallback,
      #fallback br {
        display: none;
      }
    </style>

    <!-- OVERRIDES -->
    <style type="text/css">
      .form-inline label {
        padding: 0;
      }
    </style>

    <!--[if lte IE 9]>
      <style type="text/css">
          #fallback {
              display: block;
          }
          #root {
              display: none;
          }
      </style>
      <![endif]-->
  </head>

  <body>
    <!--[if lte IE 9]>
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <i class="fas fa-exclamation-circle"></i> You are using an <strong>outdated</strong>
        browser. Please <a href="http://browsehappy.com/" target="_blank" rel="noreferrer noopener">update your browser</a>
        to improve your experience
        and security.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    <![endif]-->

    <div id="root">
    </div>

    <div id="fallback">
      {JOBS}
    </div>

    <script type="text/javascript">
      // IF THE BROWSER IS INTERNET EXPLORER 10
      if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
        console.log('Browser: IE10');
      }
      // IF THE BROWSER IS INTERNET EXPLORER 11
      var UAString = navigator.userAgent;
      if (UAString.indexOf("Trident") !== -1 && UAString.indexOf("rv:11") !== -1) {
        console.log('Browser: IE11');
      }
    </script>

    <!-- POLYFILL SERVICE  -->
    <script type="text/javascript">
      if (!(window.fetch && window.Promise && [].includes && Object.assign && window.Map)) { document.write('<script src="https://cdn.polyfill.io/v2/polyfill.min.js?features=default,fetch"></scr' + 'ipt>') }
    </script>

    <!-- CORE JS POLYFILL -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.5.7/core.min.js"
      integrity="sha256-kxCVOCrAy8XoW+tbOyHwc57xsjC8K2qMsuSPsdqCt2M=" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.5.7/library.min.js"
      integrity="sha256-pY39oFuhtlLdG+1TzoGeTLS9r8n2uiGXPkGY0Rhlr88=" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.5.7/shim.min.js"
      integrity="sha256-gktKjver6UarJBoyUcpsWDrZZA+leNn2O93cBHwvF5Y=" crossorigin="anonymous"></script>

    <!-- RAF -->
    <script src="https://cdn.jsdelivr.net/npm/request-animation-frame-polyfill@0.1.3/index.min.js"></script>

    <!-- OBJECT ASSIGN POLYFILL -->
    <script src="https://cdn.jsdelivr.net/npm/object-assign-polyfill@0.1.0/index.min.js"></script>

    <!-- FOR EACH POLYFILL -->
    <script type="text/javascript">
      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
          }
        };
      }
    </script>

    <!-- REACT DEV -->
    <!-- <script src="https://unpkg.com/react@16/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@16/umd/react-dom.development.js"></script> -->
    <!-- REACT PROD -->
    <script src="https://unpkg.com/react@16/umd/react.production.min.js"></script>
    <script src="https://unpkg.com/react-dom@16/umd/react-dom.production.min.js"></script>
    <script src="https://unpkg.com/babel-standalone@6.26.0/babel.min.js"></script>

    <!-- Other JS -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <!-- <script src="https://code.jquery.com/jquery-3.4.1.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossorigin="anonymous"></script> -->
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
      integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
      crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
      integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
      crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
      integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
      crossorigin="anonymous"></script>
    <!-- <script type="text/javascript"> -->
    <script type="text/babel">
      const handleSort = (jobs, sortKey, sortDirection) => {
        const sortedJobs = jobs.sort((a, b) => {
          let a_string = a[sortKey], b_string = b[sortKey];
          if (sortDirection === 'asc')
            return a_string.localeCompare(b_string);
          else
            return b_string.localeCompare(a_string);
        });
        return sortedJobs;
      }

      const getUniques = (jobs, filterKey) => {
        const uniques = [...new Set(jobs.map(job => {
          return job[filterKey];
        }))].filter((el) => {
          return el !== '';
        }).sort();
        return uniques;
      }

      const formatDate = (dateString) => {
        let formattedDate = '';
        if (dateString !== null) {
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];
          const dateArray = dateString.split('-');
          formattedDate =
            dateArray[2].slice(0, 2) +
            ' ' +
            months[dateArray[1] - 1] +
            ' ' +
            dateArray[0];
        }
        return formattedDate;
      }

      const DropdownFilters = props => {
        const uniqueOptions = props.staticJobs !== '' ? getUniques(props.staticJobs, props.name) : null;

        const options = uniqueOptions !== null ?
          uniqueOptions.map((option, i) => {
            return <option value={option} key={i}>{props.name === 'created_at' || props.name === 'expires_at' ? option = formatDate(option) : option}</option>;
          }) : null;

        const labelClass = props.label === null ? 'd-none' : 'mr-sm-2';

        return (
          <React.Fragment>
            <label className={labelClass}>{props.label}</label>
            <select
              className={props.filterListClass}
              value={props.selectedValue}
              name={props.name}
              disabled={props.staticJobs.length === 0 ? true : false}
              onChange={props.dropdownChanged}>
              <option value="(All)">{props.labelAll}</option>
              {options}
            </select>
          </React.Fragment>
        )
      }

      const FilterBar = props => {
        const searchInputClass = props.searchSettings.class.join(" ");
        const searchInput = props.searchSettings.enabled ?
          <React.Fragment>
            <label htmlFor="search" className="mr-sm-2">{props.searchSettings.label}</label>
            <input type="text"
              className={searchInputClass}
              id="search"
              placeholder={props.searchSettings.placeholder}
              value={props.searchText}
              onChange={props.searchChanged}
              disabled={props.staticJobs.length === 0 ? true : false}
            />
          </React.Fragment>
          : null;

        const filterListClass = props.dropdownSettings.class.join(" ");
        const filterList = props.dropdownSettings.enabled ?
          <React.Fragment>
            {props.dropdownFilters.map((filter, i) => {
              return <DropdownFilters
                filterListClass={filterListClass}
                staticJobs={props.staticJobs}
                name={filter.name}
                label={filter.label}
                selectedValue={filter.selectedValue}
                labelAll={filter.labelAll}
                dropdownChanged={props.dropdownChanged}
                key={i} />
            })}
          </React.Fragment>
          : null;

        const selectedValues = props.dropdownFilters.map(dropdown => {
          return dropdown.selectedValue === "(All)" ? false : true;
        });

        const resetButton = props.resetSettings.enabled && props.searchText !== '' || selectedValues.includes(true) ?
          <button className="btn btn-reset" onClick={() => props.resetClicked()}>{props.resetSettings.label}</button>
          : null;


        let viewTypeIcon;
        switch (props.type) {
          case 'table': viewTypeIcon = 'fas fa-table'; break;
          case 'card': viewTypeIcon = 'fas fa-th'; break;
          default: viewTypeIcon = 'fas fa-th-list';
        }

        const viewTypeClass = props.viewTypeSettings.class.join(" ");
        const menuItemClass = props.viewTypeSettings.menuItemClass.join(" ");
        const menuItemClassDisabled = 'disabled ' + props.viewTypeSettings.menuItemClass.join(" ");

        const viewTypeButton = props.viewTypeSettings.enabled && props.filteredJobs.length !== 0 ? <div className="dropdown">
          <button className={viewTypeClass} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className={viewTypeIcon}></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="viewTypeButton">
            <a className={props.type === 'table' ? menuItemClassDisabled : menuItemClass} href="#" onClick={() => props.viewTypeChanged('table')}><i className="fas fa-table"></i> Table View</a>
            <a className={props.type === 'list' ? menuItemClassDisabled : menuItemClass} href="#" onClick={() => props.viewTypeChanged('list')}><i className="fas fa-th-list"></i> List View</a>
            <a className={props.type === 'card' ? menuItemClassDisabled : menuItemClass} href="#" onClick={() => props.viewTypeChanged('card')}><i className="fas fa-th"></i> Card View</a>
          </div>
        </div> : null;

        return (
          <div id="filterbar" className="p-1">
            <div className="form-inline">
              {searchInput}
              {filterList}
            </div>
          </div>
        )
      }

      const TableHeader = props => {
        const arrowHandler = (field, activeSortKey, activeSortDirection) => {
          const arrowTop = 'fas fa-caret-up';
          const arrowBottom = 'fas fa-caret-down';
          if (field === activeSortKey) {
            return activeSortDirection === 'asc' ? arrowTop : arrowBottom;
          }
        }

        const headers = props.displayFields.map((displayField, i) => {
          if (displayField.name !== 'apply') {
            const arrowClass = arrowHandler(displayField.name, props.sortKey, props.sortDirection);
            return <th key={i} className="sortable-heading">
              <a href="#" onClick={() => props.sortClicked(displayField.name)}>{displayField.label} <i className={arrowClass}></i></a>
            </th>;
          } else {
            return props.applyButtonSettings.enabled ? <th key={i}></th> : null;
          }
        });

        return (
          <thead>
            <tr>{headers}</tr>
          </thead>
        )
      }

      const FieldType = props => {
        const newWindow = props.applyButtonSettings.newWindow ? '_blank' : '_parent';
        switch (props.field) {
          case 'title': {
            return (
              <React.Fragment>
                <a href={props.job.public_url} target={newWindow} rel="noreferrer noopener" className="job_title">{props.children}</a>
                {props.summarySettings.enabled ? <span className="summary">{props.job.summary}</span> : null}
              </React.Fragment>
            );
          } break;
          case 'apply': {
            const applyButtonClass = props.applyButtonSettings.class.join(' ');
            return props.applyButtonSettings.enabled ?
              <a href={props.job.public_url} target={newWindow} rel="noreferrer noopener">
                <button className={applyButtonClass}>{props.applyButtonSettings.label}</button>
              </a>
              : null;
          } break;
          default: {
            return <span className={props.field}>{props.children}</span>;
          }
        }
      }

      const ColumnType = props => {
        switch (props.type) {
          case 'table': {
            const columnClass = props.field === 'apply' ? "align-middle mt-2 mt-sm-0" : 'align-middle';
            return (
              <td className={columnClass}>
                {props.children}
              </td>
            );
          } break;
          case 'card': {
            const columnClass = props.field === 'apply' ? "align-middle mt-2" : 'align-middle';
            return (
              <div className={columnClass}>
                {props.children}
              </div>
            );
          }
          default: {
            const columnClass = props.field === 'apply' ? "align-middle mt-2 mt-sm-0" : 'align-middle';
            return (
              <td className={columnClass}>
                {props.children}
              </td>
            );
          }
        }
      }

      const JobsContainer = props => {
        const rows = props.filteredJobs.map((job, i) => {
          const columns = props.displayFields.map((field, i) => {
            const columnValue = (field.name === 'created_at') || (field.name === 'expires_at') ?
              formatDate(job[field.name])
              : job[field.name];

            const fieldLabel = field.name !== 'title' && job[field.name] !== '' ? <span className="field-label">{field.label}:</span> : null;

            return (
              <ColumnType type={props.type} field={field.name} key={i}>
                <FieldType job={job} field={field.name} applyButtonSettings={props.applyButtonSettings} summarySettings={props.summarySettings}>
                  {fieldLabel} {columnValue}
                </FieldType>
              </ColumnType>
            );
          });

          switch (props.type) {
            case 'table': {
              return (
                <tr key={i}>
                  {columns}
                </tr>
              )
            } break;
            case 'card': {
              const cardGrid = props.cardSettings.cardGridClass.join(" ");
              const cardClass = props.cardSettings.class.join(" ");
              return (
                <div className={cardGrid} key={i}>
                  <div className={cardClass}>
                    <div className="card-body">
                      {columns}
                    </div>
                  </div>
                </div>
              )
            } break;
            default: {
              return (
                <tr key={i}>
                  {columns}
                </tr>
              )
            }
          }
        });

        switch (props.type) {
          case 'table': {
            const tableClass = props.tableSettings.class.join(' ');
            return (
              <div id="table" className="table-responsive">
                <table className={tableClass}>
                  {props.filteredJobs.length > 0 ?
                    <TableHeader
                      displayFields={props.displayFields}
                      sortKey={props.sortKey}
                      sortDirection={props.sortDirection}
                      sortClicked={props.sortClicked}
                      applyButtonSettings={props.applyButtonSettings}
                    />
                    : null}
                  <tbody>
                    {rows}
                  </tbody>
                </table>
              </div>
            )
          } break;
          case 'card': {
            return (
              <div id="card" className="row no-gutters">
                {rows}
              </div>
            )
          } break;
          default: {
            const listClass = props.listSettings.class.join(' ');
            return (
              <div id="table" className="table-responsive">
                <table className={listClass}>
                  {props.filteredJobs.length > 0 ?
                    <TableHeader
                      displayFields={props.displayFields}
                      sortKey={props.sortKey}
                      sortDirection={props.sortDirection}
                      sortClicked={props.sortClicked}
                      applyButtonSettings={props.applyButtonSettings}
                    />
                    : null}
                  <tbody>
                    {rows}
                  </tbody>
                </table>
              </div>
            )
          }
        }
      }

      const Layout = props => {
        const filterBar = props.filterSettings.enabled ?
          <div className={props.grid.filterbarGridClass}>
            <FilterBar
              type={props.type}
              viewTypeChanged={props.viewTypeChanged}
              viewTypeSettings={props.viewTypeSettings}
              searchSettings={props.filterSettings.searchFilter}
              dropdownSettings={props.filterSettings.dropdownFilter}
              resetSettings={props.resetSettings}
              dropdownFilters={props.dropdownFilters}
              staticJobs={props.staticJobs}
              filteredJobs={props.filteredJobs}
              searchText={props.searchText}
              searchChanged={props.searchChanged}
              dropdownChanged={props.dropdownChanged}
              resetClicked={props.resetClicked}
            />
          </div> : null;

        const sortOptions = props.sortFields.map((field, i) => {
          const fieldValues = props.staticJobs.map(job => {
            return job[field.name];
          });

          if (fieldValues.join('') !== '') {
            const asc = field.name + ',asc';
            const dsc = field.name + ',dsc';
            return (
              <React.Fragment key={i}>
                <option value={asc}>{field.label} (Ascending)</option>
                <option value={dsc}>{field.label} (Descending)</option>
              </React.Fragment>
            )
          }
        });

        const sortDropdownClass = props.filterSettings.class.join(' ');
        const sortDropdown = props.sortDropdown.enabled && props.filteredJobs.length > 1 ?
          <div id="sortDropdown" className={props.type === 'table' ? 'mobile-only' : null}>
            <select className={sortDropdownClass} name="sortDropdown" onChange={props.sortDropdownChanged}>
              <option value="">Sort By...</option>
              {sortOptions}
            </select>
          </div> : null;

        const jobsFound = props.jobCountSettings.enabled ?
          <label className="m-1">
            Displaying {props.jobsInPage} of {props.staticJobs.length} job{props.staticJobs.length > 1 ? 's' : ''}.
          </label> : null;

        const secondaryBar = props.secondaryBarSettings.enabled && props.filteredJobs.length > 0 ?
          <div className="row m-2">
            <div className="mr-auto align-middle">
              {jobsFound}
            </div>
            <div className="ml-auto">
              {sortDropdown}
            </div>
          </div> : null;

        return (
          <div className="row no-gutters">
            {filterBar}
            <div className={props.grid.joblistGridClass}>
              {secondaryBar}
              {props.children}
            </div>
          </div>
        )
      }

      const filterHandler = (jobs, searchText, filterKeys, filterValues) => {
        let searchFilteredJobs = [];
        if (jobs.length > 0) {
          jobs.map(job => {
            if (job.title.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
              return
            }
            searchFilteredJobs.push(job);
          });
        }
        let filteredJobs = [];
        if (filterValues.length > 0) {
          filteredJobs = searchFilteredJobs.length > 0 ?
            searchFilteredJobs.filter((e) => {
              return filterKeys.every((a) => {
                return filterValues.includes(e[a])
              })
            }
            ) : [];
        }
        return filterValues.length > 0 ? filteredJobs : searchFilteredJobs;
      }

      const App = props => {
        const [viewType, setViewType] = React.useState(props.settings.type);
        const [sortKey, setSortKey] = React.useState(props.settings.sort.sortKey);
        const [sortDirection, setSortDirection] = React.useState(props.settings.sort.sortDirection);
        const [searchText, setSearchText] = React.useState('');
        const [filterKeys, setFilterKeys] = React.useState([]);
        const [filterValues, setFilterValues] = React.useState([]);
        const [dropdownFilters, setDropdownFilters] = React.useState(props.dropdownFilters);
        const [currentPage, setCurrentPage] = React.useState(1);

        const searchTextChangeHandler = e => {
          setSearchText(e.target.value);
          setCurrentPage(1);
        }

        const viewTypeChangeHandler = (type) => {
          setViewType(type);
        }

        const dropdownChangeHandler = e => {
          const newDropdownFilters = dropdownFilters.map(dropdownFilter => {
            return dropdownFilter.name === e.target.name ?
              { ...dropdownFilter, 'selectedValue': e.target.value }
              : { ...dropdownFilter };
          });

          let newFilterKeys = [...filterKeys];
          let newFilterValues = [];

          if (e.target.value === '(All)') {
            newFilterKeys.map((filterKey, i) => {
              if (filterKey === e.target.name) {
                newFilterKeys.splice(i, 1);
              }
            });
          } else {
            if (!filterKeys.includes(e.target.name)) {
              newFilterKeys.push(e.target.name);
            }
          }

          newFilterKeys.map(newFilterKey => {
            if (e.target.name === newFilterKey) {
              newFilterValues.push(e.target.value);
            } else {
              newDropdownFilters.map(dropdownFilter => {
                if (dropdownFilter.name === newFilterKey) {
                  newFilterValues.push(dropdownFilter.selectedValue);
                }
              });
            }
          });

          setDropdownFilters(newDropdownFilters);
          setFilterKeys(newFilterKeys);
          setFilterValues(newFilterValues);
          setCurrentPage(1);
        }

        const resetClickHandler = () => {
          const newDropdownFilters = props.dropdownFilters.map(dropdownFilter => {
            return { ...dropdownFilter, 'selectedValue': '(All)' };
          });

          setDropdownFilters(newDropdownFilters);
          setSearchText('');
          setFilterKeys([]);
          setFilterValues([]);
          setCurrentPage(1);
        }

        const sortClickHandler = newSortKey => {
          let newSortDirection = 'asc';
          if (newSortKey === sortKey) {
            newSortDirection = sortDirection === 'asc' ? 'dsc' : 'asc';
          }

          setSortKey(newSortKey);
          setSortDirection(newSortDirection);
          setCurrentPage(1);
        }

        const sortDropdownHandler = e => {
          const sort = e.target.value.split(',');
          if (e.target.value !== '') {
            setSortKey(sort[0]);
            setSortDirection(sort[1]);
            setCurrentPage(1);
          }
        }

        const split = (arr, n) => {
          var res = [];
          while (arr.length) {
            res.push(arr.splice(0, n));
          }
          return res;
        }

        const filteredJobs = filterHandler(handleSort(props.staticJobs, sortKey, sortDirection), searchText, filterKeys, filterValues);

        const errorMessage = props.staticJobs.length === 0 ?
          <label className="m-2">{props.settings.error.noJobsLabel}</label>
          : filteredJobs.length === 0 ?
            <label className="m-2">{props.settings.error.noMatchLabel}</label>
            : null;

        const pagedJobs = filterHandler(handleSort(props.staticJobs, sortKey, sortDirection), searchText, filterKeys, filterValues);
        const pageCollection = filteredJobs.length > props.settings.pagination.jobsPerPage ?
          split(pagedJobs, props.settings.pagination.jobsPerPage)
          : [filteredJobs];

        const pageButtons = pageCollection.map((page, i) => {
          return (
            <li className={currentPage === i + 1 ? 'page-item active disabled' : 'page-item'} key={i}>
              <a className="page-link" href="#" onClick={() => setCurrentPage(i + 1)}>{i + 1}</a>
            </li>
          );
        });

        const pagination = props.settings.pagination.enabled && filteredJobs.length !== 0 && pageCollection.length > 1 ?
          <div className="d-flex justify-content-center mt-2">
            <nav id="pagination" aria-label="pagination">
              <ul className="pagination">
                <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                  <a className="page-link" href="#" tabIndex="-1" onClick={() => setCurrentPage(currentPage - 1)} aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {pageButtons}
                <li className={currentPage === pageCollection.length ? 'page-item disabled' : 'page-item'}>
                  <a className="page-link" href="#" tabIndex="-1" onClick={() => setCurrentPage(currentPage + 1)} aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          : null;

        return (
          <Layout
            grid={props.settings.layout}
            type={viewType}
            viewTypeChanged={viewTypeChangeHandler}
            viewTypeSettings={props.settings.buttons.viewTypeButton}
            filterSettings={props.settings.filters}
            secondaryBarSettings={props.settings.secondaryBar}
            resetSettings={props.settings.buttons.resetButton}
            dropdownFilters={dropdownFilters}
            staticJobs={props.staticJobs}
            searchText={searchText}
            searchChanged={searchTextChangeHandler}
            dropdownChanged={dropdownChangeHandler}
            resetClicked={resetClickHandler}
            sortDropdown={props.settings.sort.sortDropdown}
            sortDropdownChanged={sortDropdownHandler}
            sortFields={props.displayFields}
            filteredJobs={filteredJobs}
            jobCountSettings={props.settings.jobCount}
            currentPage={currentPage}
            jobsPerPage={props.settings.jobsPerPage}
            jobsInPage={pageCollection[currentPage - 1].length}
          >
            {filteredJobs.length !== 0 ?
              <JobsContainer
                filteredJobs={props.settings.pagination.enabled ? pageCollection[currentPage - 1] : filteredJobs}
                type={viewType}
                displayFields={props.displayFields}
                sortKey={sortKey}
                sortDirection={sortDirection}
                sortClicked={sortClickHandler}
                cardSettings={props.settings.card}
                tableSettings={props.settings.table}
                listSettings={props.settings.list}
                applyButtonSettings={props.settings.buttons.applyButton}
                summarySettings={props.settings.summary}
              /> : null
            }
            {pagination}
            {errorMessage}
          </Layout>
        )
      }

      let joblistElements = ${jobs}

      const portalSettingElement = document.querySelectorAll('#joblist');

      let portalSetting = [];
      if (${isPreview}) {
        portalSetting = {
          group_by: "brand",
          new_window: false,
          sort_direction: "asc",
          sort_key: "title"
        }
      } else {
        Array.prototype.forEach.call(portalSettingElement, (data, i) => {
          portalSetting = {
            group_by: data.getAttribute('data-group_by'),
            new_window: data.getAttribute('data-new_window') === 'true' ? true : false,
            sort_direction: data.getAttribute('data-sort_ascending') ? 'asc' : 'dsc',
            sort_key: data.getAttribute('data-sort_by_field') === null ? 'title' : data.getAttribute('data-sort_by_field'),
          }
        });
      }

      let joblist = [];

      if (${isPreview}) {
        joblist = joblistElements;
      } else {
        Array.prototype.forEach.call(joblistElements, (job, i) => {
          // Remove HTML in Summary
          const div = document.createElement("div");
          div.innerHTML = job.getAttribute('data-summary');
          const summary = div.textContent || div.innerText || "";
  
          // Enable HTML in Summary
          // const summary = job.getAttribute('data-summary');
  
          const obj = {
            address_state: job.getAttribute('data-address_state'),
            brand: job.getAttribute('data-brand'),
            brand_url: job.getAttribute('data-brand_url'),
            classifications: job.getAttribute('data-classifications'),
            created_at: job.getAttribute('data-created_at'),
            employment_status: job.querySelector('.employment_status').innerText,
            expires: job.querySelector('.expires').innerText,
            expires_at: job.getAttribute('data-expires_at'),
            published_at: job.querySelector('.published_at').innerText,
            external: job.getAttribute('data-external'),
            id: job.getAttribute('data-id'),
            internal: job.getAttribute('data-internal'),
            location: job.getAttribute('data-location'),
            postcode: job.getAttribute('data-postcode'),
            public_url: job.getAttribute('data-url'),
            reference: job.getAttribute('data-reference'),
            salary_info: job.getAttribute('data-salary_info'),
            summary: summary,
            tag_list: job.getAttribute('data-tag_list'),
            title: job.getAttribute('data-title'),
            vacancies: job.getAttribute('data-vacancies')
          }
          joblist.push(obj);
        });
      }

      const stringifiedStyleObject = ${JSON.stringify(styleObject)}

      const dropdownFilters = [
        {
          'name': 'location',
          'label': null,
          'labelAll': 'All Locations',
          'selectedValue': '(All)',
          'state': 'displayFilterLocation'
        },
        {
          'name': 'employment_status',
          'label': null,
          'labelAll': 'All Employments',
          'selectedValue': '(All)',
          'state': 'displayFilterEmployment'
        },
        {
          'name': 'brand',
          'label': null,
          'labelAll': 'All Brands',
          'selectedValue': '(All)',
          'state': 'displayFilterBrands'
        }
      ];

      const displayFields = [
        {
          'name': 'title',
          'label': 'Position',
          'state': 'displayTitle',
        },
        {
          'name': 'location',
          'label': 'Location',
          'state': 'displayLocation',
        },
        {
          'name': 'employment_status',
          'label': 'Employment Type',
          'state': 'displayEmploymentStatus',
        },
        {
          'name': 'brand',
          'label': 'Brand',
          'state': 'displayBrand',
        },
        {
          'name': 'vacancies',
          'label': 'Vacancies',
          'state': 'displayVacancies',
        },
        {
          'name': 'created_at',
          'label': 'Created Date',
          'state': 'displayCreatedAt',
        },
        {
          'name': 'expires_at',
          'label': 'Closing Date',
          'state': 'displayExpiresAt',
        },
        {
          'name': 'published_at',
          'label': 'Published Date',
          'state': 'displayPublishedAt',
        },
        {
          'name': 'classifications',
          'label': 'Classifications',
          'state': 'displayClassifications',
        },
        {
          'name': 'apply',
          'state': 'displayButton',
        }
      ];

      const displayFieldsObject = displayFields.filter(field => stringifiedStyleObject[field.state])
      const dropdownFilterObject = dropdownFilters.filter(field => stringifiedStyleObject[field.state])

      const settings = {
        'layout': {
          'filterbarGridClass': ['col-md-12'],
          'joblistGridClass': ['col-md-12']
        },
        'type': '${styleObject.layoutStyle}', // table, card, list
        'table': {
          'class': ['table', 'table-full', 'table-sm'] // table-sm, table-bordered, table-striped
        },
        'list': {
          'class': ['table', 'table-list', 'table-sm'] // table-sm, table-bordered, table-striped
        },
        'card': {
          'cardGridClass': ['col-sm-4'],
          'class': ['card'] // text-center
        },
        'filters': {
          'enabled': true,
          'class': ['form-control', 'form-control-sm'], // form-control-sm, form-control-lg
          'searchFilter': {
            'enabled': ${styleObject.displayFilterSearch},
            'class': ['form-control', 'form-control-sm', 'mr-sm-2', 'mb-1', 'mb-sm-1', 'mt-1', 'mt-sm-1'], // form-control-sm, form-control-lg
            'label': '',
            'placeholder': 'Search',
          },
          'dropdownFilter': {
            'enabled': true,
            'class': ['form-control', 'form-control-sm', 'mr-sm-2', 'mb-1', 'mb-sm-1', 'mt-1', 'mt-sm-1'], // form-control-sm, form-control-lg
          }
        },
        'secondaryBar': {
          'enabled': false
        },
        'buttons': {
          'applyButton': {
            'enabled': true,
            'class': ['btn', 'btn-apply', 'text-nowrap', 'p-0'], // btn-sm, btn-lg, btn-block, rounded-0, btn-custom
            'label': 'Apply',
            'newWindow': portalSetting.new_window
          },
          'resetButton': {
            'enabled': false,
            'class': ['btn', 'btn-reset'],
            'label': 'Clear Filters'
          },
          'viewTypeButton': {
            'enabled': false,
            'class': ['btn', 'btn-sm', 'dropdown-toggle', 'btn-view-type'],
            'menuItemClass': ['dropdown-item', 'btn-sm']
          }
        },
        'sort': {
          'sortKey': portalSetting.sort_key,
          'sortDirection': portalSetting.sort_direction,
          'sortDropdown': {
            'enabled': true
          }
        },
        'jobCount': {
          'enabled': true
        },
        'summary': {
          'enabled': false
        },
        'error': {
          'noJobsLabel': 'There are no vacancies at present. Please check back later.',
          'noMatchLabel': 'There are no jobs matching your filter.'
        },
        'pagination': {
          'enabled': true,
          'jobsPerPage': ${parseInt(styleObject.jobsPerPage)}
        }
      }

      ReactDOM.render(
        <App staticJobs={joblist} dropdownFilters={dropdownFilterObject} displayFields={displayFieldsObject} settings={settings} />, document.getElementById('root')
      )
    </script>

    <script src="https://candidate-office.s3.amazonaws.com/js/iframe-resizer/iframeResizer.contentWindow.min.js"
      type="text/javascript"></script>

  </body>

  </html>
`)}

export default generateCode
