import React, { Component } from 'react';

import ColorPicker from './ColorPicker';

export default class Colour extends Component {
    constructor(props) {
        super(props);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        this.props.onInputChange(e);
        // Switch color to Transparent
        const switchName = e.target.name; // get name of switch
        let colorName = switchName.slice(7); // remove prefix "display"
        colorName = 'color' + colorName; // add prefix "color"

        const currentSwitch = this.props.states[switchName];
        if (currentSwitch) {
            const event = {
                target: {
                    name: colorName,
                    value: 'transparent'
                }
            }
            this.props.onInputChange(event);
        }
    }

    handleColorChange(color, name) {
        this.props.onColorChange(color, name);
    }

    handleBadge(color) {
        let badge = {
            backgroundColor: color,
            marginRight: '10px',
            border: '1px solid #efefef'
        }
        return badge;
    }

    handleColorPanel(label, badgeStyle, color, colorPickerName, colorType, switchName, colorStatus) {
        let panel = [];
        let colorSwitch = colorType === 'switch' ? this.handleColorSwitch(switchName, colorStatus) : '';
        let colorPicker = <ColorPicker name={colorPickerName} width="100%" color={color} onColorChange={this.handleColorChange} />;

        panel.push(<li key="0">
            <div className="collapsible-header">{label} <span className="badge" style={badgeStyle}></span>  {color}</div>
            <div className="collapsible-body">
                <span>
                    {colorSwitch}
                    {colorStatus ? colorPicker : ''}
                </span>
            </div>
        </li>);
        return panel;
    }

    handleColorSwitch(name, bool) {
        let colorSwitch = [];
        colorSwitch.push(<div className="input-field" key="0">
            <div className="switch center">
                <label>
                    Transparent
                        <input type="checkbox" name={name} checked={bool} onChange={this.handleInputChange} />
                    <span className="lever"></span>
                    Colour
                    </label>
            </div>
        </div>
        );
        return colorSwitch;
    }

    render() {
        const states = this.props.states;
        
        // Text Color Picker
        const colorJobTitleBadgeStyle = this.handleBadge(states.colorTextJobTitle);
        const colorJobTitlePicker = this.handleColorPanel('Job Title', colorJobTitleBadgeStyle, states.colorTextJobTitle, 'colorTextJobTitle', 'no switch', '', true);

        const colorJobTitleHoverBadgeStyle = this.handleBadge(states.colorTextJobTitleHover);
        const colorJobTitleHoverPicker = this.handleColorPanel('Job Title: Hover', colorJobTitleHoverBadgeStyle, states.colorTextJobTitleHover, 'colorTextJobTitleHover', 'no switch', '', true);

        const colorDetailsBadgeStyle = this.handleBadge(states.colorTextDetails);
        const colorDetailsPicker = this.handleColorPanel('Details', colorDetailsBadgeStyle, states.colorTextDetails, 'colorTextDetails', 'no switch', '', true);

        const colorHeadersBadgeStyle = this.handleBadge(states.colorTextHeaders);
        const colorHeadersPicker = states.displayHeaders ? this.handleColorPanel('Headers', colorHeadersBadgeStyle, states.colorTextHeaders, 'colorTextHeaders', 'no switch', '', true) : '';
        
        const colorButtonBadgeStyle = this.handleBadge(states.colorButton);
        const colorButtonPicker = states.displayButton ? this.handleColorPanel('Apply Button', colorButtonBadgeStyle, states.colorButton, 'colorButton', 'no switch', '', true) : '';
        
        const colorButtonHoverBadgeStyle = this.handleBadge(states.colorButtonHover);
        const colorButtonHoverPicker = states.displayButton ? this.handleColorPanel('Apply Button: Hover', colorButtonHoverBadgeStyle, states.colorButtonHover, 'colorButtonHover', 'no switch', '', true) : '';
        
        const colorPaginationBadgeStyle = this.handleBadge(states.colorPagination);
        const colorPaginationPicker = states.displayPagination ? this.handleColorPanel('Pagination', colorPaginationBadgeStyle, states.colorPagination, 'colorPagination', 'no switch', '', true) : '';
        
        const colorHeadingsBadgeStyle = this.handleBadge(states.colorHeadings);
        const colorHeadingsPicker = this.handleColorPanel('Headings', colorHeadingsBadgeStyle, states.colorHeadings, 'colorHeadings', 'no switch', '', true);

        // Background Color Picker
        const colorBackgroundBadgeStyle = this.handleBadge(states.colorBackground);
        const colorBackgroundPicker = this.handleColorPanel('Background', colorBackgroundBadgeStyle, states.colorBackground, 'colorBackground', 'switch', 'displayColorBackground', states.displayColorBackground);
        
        const colorJobBadgeStyle = this.handleBadge(states.colorJob);
        const colorJobPicker = this.handleColorPanel('Jobs', colorJobBadgeStyle, states.colorJob, 'colorJob', 'switch', 'displayColorJob', states.displayColorJob);
        
        const colorJobHoverBadgeStyle = this.handleBadge(states.colorJobHover);
        const colorJobHoverPicker = this.handleColorPanel('Jobs: Hover', colorJobHoverBadgeStyle, states.colorJobHover, 'colorJobHover', 'switch', 'displayColorJobHover', states.displayColorJobHover);

        return (
            <div className="card hoverable">
                <div className="card-content">
                    <span className="card-title">Colours</span>
                    <div className="card-panel no-padding">
                        <div className="row no-margin-bottom">
                            <div className="col s12">
                                <ul className="tabs tabs-secondary">
                                    <li className="tab col s6"><a href="#tab-textcolour" className="active">Text</a></li>
                                    <li className="tab col s6"><a href="#tab-backgroundcolour">Background</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col s12">
                            <ul id="tab-textcolour" className="collapsible">
                                {colorJobTitlePicker}
                                {colorJobTitleHoverPicker}
                                {colorDetailsPicker}
                                {colorHeadersPicker}
                                {colorButtonPicker}
                                {colorButtonHoverPicker}
                                {colorPaginationPicker}
                                {colorHeadingsPicker}
                            </ul>
                            <ul id="tab-backgroundcolour" className="collapsible">
                                {colorBackgroundPicker}
                                {colorJobPicker}
                                {colorJobHoverPicker}
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>

        );
    }
}
