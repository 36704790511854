import React from 'react';

import './Nav.css';

import Layout from './Layout/Layout';

class AppContainer extends React.Component {
    render() {
        return (
            <Layout />
        )
    }
}

export default AppContainer;