import React, { Component } from 'react';
import Modal from '../Helpers/Modal';

import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class Code extends Component {

    render() {

        let code = this.props.handleGenerateCode(this.props.styles, false);

        let copyCodeTitle = 'Code Copied!';
        let copyCodeText = [];
        copyCodeText.push(<p key="0">Paste the code in the Portal Template in Scout Talent :Recruit then click the "CREATE" button.</p>);
        let copyCodeImage = '/images/how-to_portal.png';

        return (
            <div className="row">
                <div className="col s12">
                    <div className="card hoverable">
                        <div className="card-content">
                            <span className="card-title">Code</span>
                            <div>
                                <div className="card black white-text">
                                    <div id="code" className="card-content">
                                        {code}
                                    </div>
                                    <div className="card-action white black-text">
                                        <CopyToClipboard text={code}><a href="#modal-copycode" className="modal-trigger blue-text">Copy Code to Clipboard</a></CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="modal-copycode" title={copyCodeTitle} text={copyCodeText} image={copyCodeImage} />
            </div>
        );
    }
}
